
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.apiEndPointGetPackage="/bx_block_landingpage2/best_selling_package";
exports.apiEndPointGetInPackage="bx_block_landingpage2/show_international_package"
exports.apiEndPointGetInOffers="bx_block_landingpage2/offers"
// Customizable Area End
