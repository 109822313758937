import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    CardMedia,
    Card,
    List,
    ListItem,
    ListItemText
    // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import { image1, image2, couponIcon } from "./assets";
import "../assets/style.css"
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2, 
      partialVisibilityGutter: 150,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter:0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter:0,
    }
  };
// Customizable Area End

import PackageDetailController, {
    Props,
} from "./PackageDetailController.web";
import PackageSearch from "./PackageSearch.web";
import Carousel from "react-multi-carousel";

export default class PackageDetail extends PackageDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   

    // Customizable Area End

    render() {
        let dayPlan = [{ "id": "1", "value": "9 June, Sun" },
        { "id": "2", "value": "10 June, Mon" },
        { "id": "3", "value": "11 June, Tue" },
        { "id": "4", "value": "12 June, Wed" },
        { "id": "5", "value": "13 June, Thu" },
        { "id": "6", "value": "14 June, Fri" },
        { "id": "7", "value": "15 June, Sat" },
        ]
        const couponsData = [
            { id: 1, code: 'DOOLWA 30' },
            { id: 2, code: 'WELCOME 30' },
            { id: 3, code: 'FLY 20' },
            { id: 4, code: 'WELCOMEDOOLWA 20' },
            { id: 5, code: 'DOOLWA 10' }
        ];
        return (
            // Customizable Area Start

            <>
                <PackageSearch package={""} navigation={this.props.navigation} id={""} onSendData={undefined} />
                <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 60px" }, }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="headTopDetail">
                                <Typography variant="h5" data-test-id="mainHeading" className="headingdetail">
                                    {this.state.singlePackageData?.attributes.name}
                                </Typography>

                            </div>



                        </Grid>
                    </Grid>
                    <Box className="boxShadowDetail">
                    <Grid spacing={2} container>
                            {this.state.singlePackageData?.attributes.itinanary_all_images.length===4?<>
                                <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card">
                                    <CardMedia
                                        component="img"
                                        alt="Beach huts"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[0]}
                                        title="Beach huts"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card sub-image" style={{ marginBottom: "10px" }}>
                                    <CardMedia
                                        component="img"
                                        alt="Temple"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[1]}
                                        title="Temple"
                                    />
                                </Card>
                                <Card className="image-card sub-image">
                                    <CardMedia
                                        component="img"
                                        alt="Cityscape"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[2]}
                                        title="Cityscape"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card">
                                    <CardMedia
                                        component="img"
                                        alt="Beach huts"
                                        image={this.state.singlePackageData?.attributes.itinanary_all_images[3]}
                                        title="Beach huts"
                                    />
                                </Card>
                            </Grid>
                            </>:<>
                            {this.state.singlePackageData?.attributes.itinanary_all_images
    ?.slice(0, 4)
    .map((item: any, index: any) => (
                <Grid item xs={12} sm={this.getGridSize(this.state.singlePackageData?.attributes.itinanary_all_images.length)} key={index}>
                    <Card className="image-card">
                        <CardMedia
                            component="img"
                            alt={`Image ${index + 1}`}
                            image={item}
                            title={`Image ${index + 1}`}
                        />
                    </Card>
                </Grid>


            ))}
                   
                   
                            </>
                            }
                           
                        </Grid>
                        <Typography variant="body1" className="itinerary">
                            ITINERARY
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box className="boxShadowDetail">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Box>
                                            <Typography data-test-id="dayPlan" style={{ fontSize: "24px", fontWeight: 700, padding: "15px 0", textAlign: "center" }}>7 Day Plan</Typography>
                                            <ul className="dayplanUl">
                                                {dayPlan.map((item: any) => (
                                                    <li style={{ background: item.id === "1" ? "rgba(23, 100, 160, 1)" : "rgb(233, 243, 252)", color: item.id === "1" ? "#fff" : "#000" }}
                                                        key={item.id}>{item.value}
                                                    </li>
                                                ))}

                                            </ul>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9}>
                                        <div style={{ position: "relative" }}>
                                            <Box sx={{ position: { xs: "relative", sm: "relative", md: "absolute" }, top: "11px", right: "0" }}>
                                                <Button variant="outlined" style={webStyleDetail.ButtonStyle1}>
                                                    {this.state.singlePackageData?.attributes.day}
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button data-test-id="DownloadPdf"
                                                 onClick={() => window.open(this.state.singlePackageData?.attributes.download_pdf, '_blank')} 
                                                 variant="outlined" style={webStyleDetail.ButtonStyle2}>
                                                    Download PDF
                                                </Button>
                                            </Box>
                                           {
                                            this.state.singlePackageData?.attributes.itenanries.data.map((item:any)=>(
                                                <>
                                                 <Typography variant="h5" className="sectionTitle">
                                                <span style={webStyleDetail.ButtonStyle}> {item.attributes.title}</span>
                                            </Typography>
                                            <Typography variant="body1" className="textPrePackages" dangerouslySetInnerHTML={{ __html: item.attributes.description }} />
                                            <Grid container spacing={2}>
                                           <Grid item xs={12}>
                                           <Carousel 
          responsive={responsive}
                infinite={true}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                containerClass="carousel-container"
                partialVisbile={false}
                arrows={item.attributes.image_url.length>3?true:false} 
                className={item.attributes.image_url.length>3?"":"coustomSlider"}
                >
            {item.attributes.image_url?.map((item:any, index: number) => (
              <div key={index} style={{ position: "relative",margin:"10px" }}>
                <img src={item} className="image-cardMulti" title={`Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
                                           </Grid>
                                               

                                               
                                            </Grid>
                                            <ul className="listDetailsUl">                 
                                                <li>Roundtrip Flights</li>
                                                <li>Selected Meals</li>
                                                <li>3 Star Hotel</li>
                                            </ul>
                                                </>
                                            ))
                                           }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item xs={12} sm={12} md={4}>
                            <Box className="boxShadowDetailCoupon">
                                <Box >
                                    <Box style={{ textAlign: "center" }}>
                                        <Typography style={{ fontSize: "24px", fontWeight: 700, padding: "15px 0" }}>Rs.{this.state.singlePackageData?.attributes.price}/Adult</Typography>
                                        <Button
                                            type="submit"
                                            data-test-id="handleSubmitOrderMgmtss"
                                            style={{
                                                background: "rgba(23, 100, 160, 1)",
                                                borderRadius: "5px",
                                                padding: "10px 35px",
                                                marginTop: "10px", color: "#fff",
                                                fontSize: "16px", textTransform: "capitalize",
                                                fontWeight: 700
                                            }}
                                        >Proceed To Payment</Button>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%" }}>
                                            <Typography data-test-id="Coupons" variant="h6" style={{ padding: "20px 17px", fontSize: "16px", fontWeight: 700 }}>Coupons & Offers</Typography>
                                            <List>
                                                {couponsData.map((coupon, index) => (
                                                    <ListItem key={coupon.id} divider >
                                                        <img src={couponIcon} />&nbsp;&nbsp;
                                                        <ListItemText className="textCoupon" primary={coupon.code} />
                                                        <Button
                                                            variant="outlined"
                                                            style={{ color: "rgba(23, 100, 160, 1)", background: "none", border: "none" }}
                                                            data-test-id={`${"handleApplyCoupon"}${index}`}
                                                            onClick={() => this.handleApplyCoupon(coupon.code)}
                                                        >
                                                            <span style={webStyleDetail.textCouponApply}>
                                                                {this.state.appliedCoupon === coupon.code ? (
                                                                    <>
                                                                        APPLIED <span style={{ marginLeft: '10px', cursor: 'pointer' }}>&times;</span>
                                                                    </>
                                                                ) : (
                                                                    'APPLY'
                                                                )}
                                                            </span>

                                                        </Button>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
let webStyleDetail = {
    ButtonStyle: {
        display: "inline-block",
        padding: "10px",
        fontSize: "14px",
        FontWeight: 700,
        lineHeight: "18px",
        marginTop: "12px",
        marginBottom: "12px",
        color: "white",
        borderRadius: "0",
        background: "rgba(23, 100, 160, 1)"
    },
    ButtonStyle1: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#000",
        background: "#fff",
        border: "1px solid rgb(23, 100, 160)"
    },
    ButtonStyle2: {

        fontSize: "14px",
        fontWeight: 700,
        color: "white",
        background: "rgba(23, 100, 160, 1)"
    },
    textCouponApply: {
        fontSize: "16px", fontWeight: 700
    }
}

// Customizable Area End

