// Customizable Area Start
import React,{CSSProperties,Suspense } from "react";
import OffersController, { Desti, Props } from "./PromocodesController";
import { Box, Button, Grid, Typography,CircularProgress,Snackbar } from "@material-ui/core";
import { CheckCircleOutline} from "@material-ui/icons";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import CopyText from "../../../components/src/CopyText";
import Footer from "../../../components/src/Footer";
import OfferBanner from "../../../components/src/OfferBanner"
import ImageWithSkeleton from "../../../components/src/ImageWithSkeleton";
const webStyleLanding={
  backgroundImage: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100%",
      opacity: 2.5,
      filter: "brightness(0.7)"
    },
    tradingImage: {
      width: "100%", 
      height: "300px", 
      borderRadius: "15px"
    },
   
}

export default class BlogpostsManagement extends OffersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>

<Snackbar open={this.state.toastOpen}  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} onClose={()=> this.setState({toastOpen:false})} autoHideDuration={3000}>
                    <Typography style={{padding:'8px',background:"rgb(23, 100, 160)",color:'white',borderRadius:'4px',display:'flex',alignItems:'center',gap:'10px'}}>
                        <CheckCircleOutline /> {this.state.CouponMsg}
                    </Typography>
                </Snackbar>
      <div style={{marginTop: "-10px"}} >
      <Suspense fallback={<CircularProgress />}>
      <Box
      data-test-id = "Header-container"
  className="offerH2Titler"
  sx={{
    position: 'relative',
    overflow: 'hidden',
  }}
>
<OfferBanner data-test-id="oceanbackground" oceanbackground={this.state.singleOfferData.data.attributes.image} />
  
  <HeaderMenuBlock navigation={this.props.navigation} id={""} />
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: { xs: "200px", sm: "250px", md: "450px" },
      position: 'relative',  
      zIndex: 1, 
    }}
  >
    <Typography
      className="blogH2Title"
      style={webStyles.TypeText}
      variant="h2"
    >
      {this.state.singleOfferData.data.attributes.tagline1}
    </Typography>
  </Box>
</Box>

     </Suspense>
     <Box key="static-box" sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"40px 60px" }}}>
                       <Box key={this.state.singleOfferData.data.attributes.id}>
                       <Box sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"15px 250px" } }}>
                       <Typography className="blogH2Title" style={webStyles.typoTitle} variant="body1" dangerouslySetInnerHTML={{ __html: 
                    this.state.singleOfferData.data.attributes.title }} />
                       </Box>
                       <Box sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"15px 250px" },marginTop:"10px" }}>

                         <Typography className="blogPTitle" style={webStyles.typoStatic} variant="body1" dangerouslySetInnerHTML={{ __html: 
                        "Book your next trip with these offers" }} />
                       </Box>
                      </Box>
                      <Grid container spacing={0}>
                          <Grid xs={12} sm={12} md={2} lg={2} item></Grid>
                               <Grid xs={12} sm={12} md={8} lg={8} item>
                                <Box style={{boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
                           borderRadius: "15px",
                           margin:" 50px 0",
                           width: "100%", background:"rgba(233, 243, 252, 1)"}}>

<Grid container spacing={4} style={{alignItems:"center",}}>
                                <Grid className="imgOfferBox paddssd" xs={12} sm={5} md={5} lg={5} item>
                                   <Box >
                                   <Suspense fallback={<CircularProgress />}>
                                       <div className="offerimgSizeheight">
                                        <ImageWithSkeleton src={this.state.singleOfferData.data.attributes.offer_code_image} type={"offerPage"} />
                                       </div>
                                       
                                       </Suspense>
                                   </Box>
                               </Grid>
                               <Grid style={{padding:"0"}} xs={12} sm={7} md={7} lg={7} item>
                                   <Box sx={{padding:"0 30px"}}>
                                       <Typography style={webStyles.typoTagline2} variant="h4">{this.state.singleOfferData.data.attributes.tagline2}</Typography>
                                       <Typography style={webStyles.typoDes} variant="h4">{this.state.singleOfferData.data.attributes?.description}</Typography>
                                       <ul style={{padding:"0",listStyleType: "none",
display: "flex", marginTop:"20px"}}>
                                          <li style={{background:"rgba(23, 100, 160, 1)",color:"#fff",padding:"10px"}}>Code:</li>
                                          <li style={{border:"1px #000 dashed",background:"#ffff",color:"#000",fontWeight:700,padding:"10px"}}>{this.state.singleOfferData.data.attributes.offer_code}</li>
                                          <CopyText data-test-id="copyText" state={this.state} clickCopyFun={this.clickCopyFun} />
                                       </ul>
                                       <Button className="buttonOffer" style={{background:"rgba(23, 100, 160, 1)", padding:"10px 25px", marginTop:"10px", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:700}} data-test-id="handleDone">&nbsp;Book Now&nbsp;</Button>
                                   </Box>
                               </Grid>
                                </Grid>
                                </Box>
                               </Grid>
                               <Grid xs={12} sm={12} md={2} lg={2} item></Grid>
                           </Grid>
                    <Box>
                    <Typography className="blogPTitle" style={{color:"rgba(0, 0, 0, 1)",marginTop:"10px",marginBottom:"35px", fontWeight:700, lineHeight:"36px", fontSize:"32px",fontFamily: 'Inter, sans-serif', textAlign:"center"}} variant="body1" dangerouslySetInnerHTML={{ __html: 
                        "Top Trending Destinations" }} />
                        <Grid container spacing={4}>
                            {
                                this.state.destinationData.map((item:Desti)=>(
                                    <Grid xs={12} sm={12} md={4} lg={3} item>
                                    <Box style={{textAlign:"center"}}>
                                      <Suspense fallback={<CircularProgress />}>
                                      <div className="offerimgSizeheightws">
                                        <ImageWithSkeleton data-test-id="offerimgSizeheightws" src={item.attributes?.image} type={"offerPage"} />
                                       </div>
                                      </Suspense> 
                                    <Typography style={{margin:"10px 0",color:"rgba(0, 0, 0, 1)", fontWeight:400, fontSize:"16px",fontFamily: 'Inter, sans-serif',}} variant="h4">{item.attributes?.name_of_destination}</Typography>
        
                                    <Button style={{background:"rgba(23, 100, 160, 1)", padding:"10px 25px", marginTop:"10px", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:700}}>&nbsp;Book Now&nbsp;</Button>
                                    </Box>
                                  </Grid>
                                ))
                            }
                         
                        </Grid>
                    </Box>
     </Box>
     <Footer />
     </div>
     </>
      // Customizable Area End
    );
  }
}
const webStyles={
    TypeText:{
        color:"#fff",fontSize:"48px",fontWeight:700,fontFamily: 'Inter, sans-serif',padding:"10px"
    } as CSSProperties,
    typoTitle:{
        textAlign:"center",color:"#000", fontWeight:700, lineHeight:"36px", fontSize:"32px",fontFamily: 'Inter, sans-serif',
    } as CSSProperties,
    typoStatic:{
        color:"rgba(0, 0, 0, 1)", fontWeight:700, lineHeight:"36px", fontSize:"32px",fontFamily: 'Inter, sans-serif', textAlign:"center"
    } as CSSProperties,
    typoTagline2:{
        margin:"20px 0",color:"rgba(0, 0, 0, 1)", fontWeight:700, lineHeight:"36px", fontSize:"24px",fontFamily: 'Inter, sans-serif',
    } as CSSProperties,
    typoDes:{
        color:"rgba(0, 0, 0, 1)", fontWeight:400, fontSize:"16px",fontFamily: 'Inter, sans-serif',
    } as CSSProperties
}
// Customizable Area End
