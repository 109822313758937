import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Drawer, Grid, MenuList, MenuItem, TextField, Menu, CircularProgress, Modal, Popover, styled, Divider, ListItem, List} from "@material-ui/core";
import { errorIcon,flightImg,Included  } from "./assets";
import Loader from "../../../components/src/Loader.web";
import {Tune,Close } from "@material-ui/icons";
import { createTheme } from "@material-ui/core/styles";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import '../assets/style.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Filteritems from "../../filteritems/src/Filteritems.web";
import Calender from "../../../components/src/Calender";
import {FlightList,FlightDetails, FlightInfo,ViewPrices, Airport} from "./RoundTripController.web";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import SingleCalender from "../../../components/src/SingleCalender";
import NotFound from "../../../components/src/NotFound";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

interface SearchObj{
    id:string,
    state:string,
    country:string,
    flight:string,
    stateCode:string
  }
// Customizable Area End
import RoundTripController, { Props } from "./RoundTripController.web";
import { configJSON } from "./CatalogueController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class RoundTrip extends RoundTripController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getTripBox(flightDetails:FlightList,indexNumber: number) {
        return (
            <Box className="flight-card" data-test-id="flight-details-card" style={{ cursor: 'default',  }} key={indexNumber}>
                {flightDetails.flight_details.map((tripList: FlightDetails, tripNumber: number)=>{
                    return(<Box className="flight-details-trip">
                    <Typography className="flight-details-heding">TRIP {tripNumber+1}</Typography>
                    <div className="flight-name-details-container">
                        <Typography className="trip-name-details">{this.tripRoute(tripList)}</Typography>
                    </div>
                    {tripList.flight_info.map((tripDetails:FlightInfo,tripDetailsNumber: number )=>{
                        return(<div className="flight-name-parent flight-sub-card" key={tripDetailsNumber}>
                        <Box className="flight-img-box">
                            <img className="flight-logo"
                                src={flightImg}
                                alt="flight logo"
                            />
                            <Box>
                                <Typography className="flight-name-img flightname">
                                   {tripDetails.AirlineName}
                                </Typography>
                                <Typography className="flightNumber" >
                                    {tripDetails.FlightNumber}
                                </Typography>
                            </Box>
                        </Box>
                        <div className="flight-timing">
                            <div className="flight-time-details">
                                <Typography className="flight-price ">
                                {this.convertFormatTime(tripDetails.DepartureTime)}
                                </Typography>
                                <Typography className="flightDate">
                                {this.convertFormatTime(tripDetails.DepartureTime)}
                                </Typography>
                                <Typography className="location">
                                   {tripDetails.Origin}
                                </Typography>
                            </div>
                            <div className="flighttime-middle ">
                                <Typography className="flight-trip-duration">
                                   {this.travelTimeDifference(tripDetails.DepartureTime, tripDetails.ArrivalTime)}
                                    </Typography>
                                <Typography style={{ color: "#1764A0", fontWeight: "400", fontSize: '13px' }} className="stops-text">
                                {tripList.flight_info[tripDetailsNumber+1] ? this.layoverTime(tripDetails.ArrivalTime,tripList.flight_info[tripDetailsNumber+1].DepartureTime, tripDetails.Destination) : "Non- Stop"}
                                </Typography>
                            </div>
                            <div className="flight-time-details">
                                <Typography className="flight-price ">
                                {this.convertFormatTime(tripDetails.ArrivalTime)}
                                </Typography>
                                <Typography className="flightDate">
                                {this.convertFormatTime(tripDetails.ArrivalTime)}
                                </Typography>
                                <Typography className="location">
                                    {tripDetails.Destination}
                                </Typography>
                            </div>
                        </div>
                    </div>)
                    })}
                </Box>)
                })}
            </Box>
        )
    }
    getRoundTripFlightsList(item: FlightList, indexNumber: number) {
        return (
            <>
                <Box sx={flightItemStyle.container} key={indexNumber} className="flight-card" data-test-id="roundtrip-flight-result" >
                    <RoundTripContainer className="topRow" style={{margin:'0px 14px'}}>
                        <Box>
                            <Typography className="multiAirLince">RoundTrip Airlines</Typography>
                            <Typography className="roundFlightName roundMarginTop">
                        {this.truncateString(item.AirlineName, 15)}
                            </Typography>
                        </Box>
                        <Box className="timeDurationContainer" >
                            <Typography className="timeDuration">
                                Duration : {this.addTimes(item.flight_details[0].travel_time, item.flight_details[1].travel_time)}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className="roundFlightNumber">
                        <Box style={{display:"flex"}}><Box>Flight No-</Box> <Box className="xflightwrap" sx={{ width:{xs:"auto",sm:"auto",md:"100px"}, whiteSpace: 'normal' }}>{item.FlightNumber}</Box></Box>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className="roundFlightPrice">{item.view_prices[0].currency_type} {item.adult_per_price}</Typography>
                            <Typography className="multiAirLince roundMarginTop">
                                Per adult
                            </Typography>
                        </Box>
                        <Button className="priceButton" data-test-id= "view-priceOpenMOdal" onClick={() => this.handleShowPriceModal(item)}>
                            View Prices
                        </Button>
                    </RoundTripContainer>
                    <DividerContainer  />
                    <Box className="flight-details-card-content">
                        {item.flight_details.map((flightTrip: FlightDetails,indexNumber: number) => {
                          return (
                                <div className="trip1">
                                    <div className="cardContainer" style={{ display: 'flex', alignItems: 'flex-start', gap: '24px', width: "60%" }}>
                                        <Typography className="trip-name">Trip {indexNumber+1}</Typography>
                                        <div className="flight-details">
                                            <Typography className="trip-content-text">{flightTrip.flight_info[0].Origin} to {flightTrip.flight_info[flightTrip.flight_info.length-1].Destination} | {this.formateDateMonth(flightTrip.flight_info[0].DepartureTime)}</Typography>
                                            <div className="flight-name-detail"><Typography className="flight-name-img" style={{ display: 'flex', alignItems: 'center'}}>
                                                <img className="flight-logo" style={{
                                                    objectFit: 'cover', width: '24px',
                                                    height: '24px',
                                                    marginRight: '8px',
                                                    borderRadius: '50%',
                                                }} src= {flightImg} alt="flight logo" />
                                                {flightTrip.flight_info[0].AirlineName}</Typography> 
                                                <Typography className="flightNumber-list" >Flight No:{flightTrip.flight_info[0].FlightNumber}</Typography></div>
                                        </div>
                                    </div>
                                    <div className="flight-timing">
                                        <div className=" timeWidth flight-time ">
                                            <Typography className="flight-price ">{this.convertFormatTime(flightTrip.flight_info[0].DepartureTime)}</Typography>
                                            <Typography className="location">{flightTrip.flight_info[0].Origin}</Typography>
                                        </div>
                                        <div className="flighttime-middle ">
                                            <Typography className="flight-trip-duration">{flightTrip.travel_time}</Typography>
                                            <Typography className="total-stops" style={{ color: "#1764A0", fontSize: "13px", fontWeight: "400" }}>{flightTrip.flight_info.length > 1 ? flightTrip.connection +" stop via " + this.layOver(flightTrip.flight_info):"Non- Stop"}</Typography>
                                        </div>
                                        <div className="timeWidth flight-time ">
                                            <Typography className="flight-price ">{this.convertFormatTime(flightTrip.flight_info[flightTrip.flight_info.length-1].ArrivalTime)}</Typography>
                                            <Typography className="location">{flightTrip.flight_info[flightTrip.flight_info.length-1].Destination}</Typography>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Box>
                    <Box className="flight-card-footer">
                        <Button style={{ display: 'flex' }} data-test-id = "handleShowDetail" onClick={(event) => this.handleShowDetail(event,indexNumber)}>
                            <Typography
                                variant="body2"
                                className="travel-class-name"
                                style={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: 'row',
                                    gap: '10px',
                                    color: "rgba(23, 100, 160, 1)"
                                }}
                                data-test-id = "flightDetaisText"
                            >
                                {"Flight Details"} {this.state.visibleDetailIndex === indexNumber ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                {
                    this.state.visibleDetailIndex === indexNumber && (
                        <Box className="flight-details-parent">{this.getTripBox(item,indexNumber)}</Box>

                    )
                }
            </>
        );
    }
    allButtonList = () => {
        return (
            <Box style={{ padding: "12px" }}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <div className="buttonContainer">
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'PremiumEconomy' ? 'active' : ''}`}
                data-test-id="handleClickStatus"
                onClick={() => this.handleClickStatus('PremiumEconomy')}
              >
                Premium /
                Economy
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'PremiumFirst' ? 'active' : ''}`}
                data-test-id="handleClickStatus2"
                onClick={() => this.handleClickStatus('PremiumFirst')}
              >
                Premium /
                First
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'First' ? 'active' : ''}`}
                data-test-id="handleClickStatus3"
                onClick={() => this.handleClickStatus('First')}
              >
                First
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'Business' ? 'active' : ''}`}
                data-test-id="handleClickStatus4"
                onClick={() => this.handleClickStatus('Business')}
              >
                Business
              </button>
              <button
                className={`buttonEconomy ${this.state.travelStatus === 'Economy' ? 'active' : ''}`}
                data-test-id="handleClickStatus5"
                onClick={() => this.handleClickStatus('Economy')}
              >
                Economy
              </button>

            </div>
          </Grid>
        </Grid>
      </Box>
        )
    }

    formInputText = () => {
        return (
            <>
                {this.state.selectAirportFrom.Code && this.state.selectAirportFrom.Name ? (
                    <div style={{ position: "absolute", width: "95%", background: "rgb(19, 81, 130)", padding: "5px 0" }}>
                        <span className="textcalenderday" style={{ fontWeight: "700", fontSize: "24px", color: "#FFFFFF",fontFamily:"Inter" }}>{this.state.selectAirportFrom.Code}</span>
                        <span className="textcalendername" style={{ fontWeight: "500", fontSize: "16px",fontFamily:"Inter", display: "block", marginTop: "10px", color: "#FFFFFF", width: "96%" }}>{this.state.selectAirportFrom.CityCode},{this.state.selectAirportFrom.Name}</span>
                    </div>
                ) : ''}
            </>
        )
    };

    toInputText = () => {
        return (
            <>
                {this.state.selectAirportTo.Code && this.state.selectAirportTo.Name ? (
                    <div style={{
                        position: "absolute", width: "92%",background: "rgb(19, 81, 130)",padding: "5px 0"
                    }}>
                        <span className="textcalenderday" style={{ fontWeight: "700", fontSize: "24px", color: "#FFFFFF" ,fontFamily:"Inter"}}>{this.state.selectAirportTo.Code}</span>
                        <span className="textcalendername" style={{ fontWeight: "500", fontSize: "16px", display: "block", marginTop: "10px", color:"#FFFFFF", fontFamily:"Inter"}}>{this.state.selectAirportTo.CityCode},{this.state.selectAirportTo.Name}</span>
                    </div>
                ) : ''}
            </>
        )
    };

    travelInputText = () => {
        return (
            <>
                {this.state.fullTravelObj.countAdult &&
                    this.state.fullTravelObj.travelStatus ? (
                    <div style={{
                        position: "absolute", width: "134%",
                        background: "#0b4f84", left: "0px", top: "25px",
                        padding: "5px 0"
                    }}>
                        <span className="calenderDay" data-test-id="formatDay">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant} </span>
                        <span className="calenderMonth" data-test-id="formatMonth">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant === 1? "Traveller":"Travellers"}</span><br />
                        <span className="calenderYear" style={{ fontWeight: "400",fontFamily:"Inter", display: "block", marginTop: "10px", color: "white" }} data-test-id="formatYear">{this.state.fullTravelObj.travelStatus}</span>
                    </div>
                ) : ''}
            </>
        )
    }

    calenderBoxUiChild = () => {
        return (
            <Popover
                data-test-id="popoverOpenTestId"
                open={Boolean(this.state.setPropover)}
                anchorEl={this.state.setPropover}
                onClose={this.propoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                { this.state.selectedTripType === "One Way" ?
                    <div className="boxCalenderDone111" style={{ borderRadius: "10px", width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", zIndex: 9999, background: "#fff" }}>
                        <div className="datepikerTop111">
                            <div className="calTitle" style={{ padding: "12px 20px", width: "auto" }}>
                                <Typography variant="h4" ><span className="font14px" data-test-id= "changeDateTextID">{this.state.startDate ? this.formatDate(this.state.startDate) : "Departure Date"}</span></Typography>
                            </div>
                            <div className="singleCalender calenderBoox">
                                <SingleCalender state={this.state} month={1} data-test-id="calenderBlock" handleDateChange={this.oneWayDateChange} />
                            </div>
                            <Box className="buttonBoxapply111">
                                <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
                            </Box>
                        </div>
                    </div>
                    : <div className="boxCalenderDone111" style={{ borderRadius: "10px", width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", background: "#fff" }}>
                        <div className="datepikerTop111">
                            <div className="calTitle" style={{ width: "auto" }}>
                                <Typography variant="h4" ><span className="font14px" data-test-id= "changeDateTextID">{this.state.startDate ? this.formatDate(this.state.startDate) : "Departure Date"}</span>&nbsp;-&nbsp;<span className="font14px">{this.state.endDate1 ? this.formatDate(this.state.endDate1) : <>Select Return Date <span className="fontWee400">(save more on two ways)</span></>}</span></Typography>
                            </div>
                            <div className="calenderBoox">
                                <Calender state={this.state} data-test-id="calenderRoundTrip" handleDateChange={this.handleChangeDate} />
                            </div>
                            <Box className="buttonBoxapply111">
                                <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
                            </Box>
                        </div>
                    </div>
                    }
            </Popover>
        )
    };

    getFlightTypeHeader = () => {
        return (
            <div className="catalogue-header">
                <Grid data-test-id="trip-type" onClick={(event) => this.handleTripClick(event)} item xs={12} sm={10} md={3} lg={2} className="catalogue-header-box trip-type-box" style={{ paddingLeft: '16px', paddingTop: '10px', cursor: 'pointer' }}>
                    {
                        this.state.errorResSearch &&
                        <span className="imgErrorBoxes" style={{ width: 'fit-content', fontSize: '14px', fontWeight: '600', margin: '-32px 0px 14px 0px', position: 'absolute' }}>
                            {this.state.errorResSearch}
                        </span>
                    }
                    <div style={{ display: "flex", alignItems: 'center', width: '100%', cursor: 'pointer' }}>
                        <Typography className="catalogue-heading">
                            TRIP TYPE
                        </Typography>
                        <ExpandMoreIcon />
                    </div>
                    <Menu
                        marginThreshold={72}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        data-test-id="handleCloseTripType"
                        id="simple-menu"
                        anchorEl={this.state.anchorElTripType}
                        PaperProps={{
                            className: "menuTrip"
                        }}
                        open={this.state.tripOpen}
                        onClose={this.handleClose}
                        keepMounted

                    >
                        <MenuList id="split-button-menu" autoFocusItem>
                            {this.state.tripOptions.map((option, indexnumber) => (                            
                                <MenuItem
                                    data-test-id = {`selectTripOption${indexnumber}`}
                                    className="catalogue-menu"
                                    key={indexnumber}
                                    selected={option.tripSelected}
                                    onClick={() => this.handleSelectTriptype(option.tripType,indexnumber)}
                                >
                                    {option.tripType}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                    <Typography className="calenderDay">{this.state.selectedTripType}</Typography>
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg={2} style={{position:"relative",}}>
        <TextField
        autoComplete="off"
        fullWidth
          name="from"
          onChange={this.handleInputChange}
          style={{ cursor: "pointer" }}
          onBlur={()=> setTimeout(()=>{
            this.handleClose()
          },300)}
          label={(
            <>
             <span className="catalogue-heading">From</span>

              {this.formInputText()}
            </>
          )}
          variant="outlined"
          placeholder={"Enter city/ Airport name"}
          data-test-id="inputCityFrom"
          value={this.state.inputApiSearchFrom}
          onClick={this.handleClickFrom}
         
          InputProps={{classes: {
              input: "catalogue-header-box",
              root: "custom-input-root",
            },

          }}
          className="catalogue-header-box"
          InputLabelProps={{
            className: "fontFrom fontLabel",
            shrink: true,
            style: { transform: "translate(14px, 7px)", cursor: "pointer", color: "#000", fontWeight: "700", fontSize: "16px", width: "100%" }
          }}
        />
        { (this.state.airportNameFrom.length> 0 && Boolean(this.state.anchorEl)) &&
         <Box className="yscrollwindow fromDropdown" sx={{ width: { xs: "94%", sm: "94%", md: "95%", lg: "96%" } }}         style={{ padding: "10px" }}>
          {this.state.airportNameFrom.map((itemObj: Airport, index: number) => (
            <Box data-test-id = {`fromMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleFromDataSelect(itemObj)}>
              <Box className="highlight-item" style={{ paddingTop: "10px", width: "100%",cursor:'pointer' }}>
                <Grid container spacing={2} 
                style={{display:'flex',
                justifyContent:"space-between",
                padding:"12px"}}>
                  <Box 
                  style={{display:'flex',gap:'10px'}}> <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                  <Grid item >
                    <Typography style={{ color: "#000", paddingBottom: "12px", fontWeight: "600", fontSize: "12px", }} variant="body1">{itemObj.Code}</Typography>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{itemObj.Name.replace("Arpt", "Airport")}</Typography>

                  </Grid></Box>
                 
                  <Grid item>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }}
                      variant="body1">{itemObj.CityCode}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
         } 
        <Box data-test-id="reverseFunct" onClick={this.reverseFunct} className="reversChangeIcon">
              <SyncAltIcon style={{color:"rgb(23, 100, 160)", cursor:"pointer"}} />
        </Box>
        </Grid>
        <Grid
         item 
         xs={12} 
         sm={5} 
         md={4} lg={2}  
         className="inputCity" 
         style={{position:
         'relative'}}>
          <TextField
          
            onBlur={()=> 
              
              setTimeout(()=>{this.handleClose()
            },
            300)}
            fullWidth
            name="to"
            label={(
              <>
              <span className="catalogue-heading">To</span>
             {this.toInputText()}
              </>
            )}
            onChange={this.handleInputChangeTo}
            value={this.state.inputApiSearchTo}
            data-test-id="inputCityTo"
            onClick={this.handleSelectTo}
            variant="outlined"
            autoComplete = "off"
            placeholder={"Enter city/ Airport name"}
            className="catalogue-header-box"
            InputProps={{
              classes: {
                root: "custom-input-root",
                input: "catalogue-header-box",
              }
            }}
            InputLabelProps={{
              className:"fontFrom fontLabel",
              shrink: true,
              style: {overflow:"hidden", height:"100%", transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px",width:"100%"}
          }}
          />
          {(this.state.airportNameTo.length >0 && Boolean(this.state.anchorElTo)) &&  <Box sx={{ width: { 
            xs: "94%", sm: "94%", md: "95%",
           lg: "96%" } }} className="yscrollwindow fromDropdown" style={{ padding: "10px" }}>
            {this.state.airportNameTo.map((itemObj: Airport, index: number) => (
                <Box data-test-id = {`toMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleSelectDataTo(itemObj)}>
                <Box style={{ width: "100%", paddingTop: "10px" }} className="highlight-item">
                  <Grid container spacing={2} style={{display:'flex',justifyContent:"space-between",padding:"12px"}}>
                    <Box style={{display:'flex',gap:'10px'}}>
                      <Grid item>
                        <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: "600" }} variant="body1">{itemObj.Code}</Typography>
                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{itemObj.Name.replace("Arpt", "Airport")}</Typography>
                      </Grid>
                    </Box>
                    <Grid item>
                      <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }} variant="body1">{itemObj.CityCode}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>}
        </Grid>
                <Grid item xs={12} sm={this.state.selectedTripType === "Round Trip" ? 5 : 10} md={4} lg={2}>
                    <TextField
                        className="catalogue-header-box"
                        name="departureDate"
                        label={(
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%' }}>
                                    <span className="catalogue-heading"> Departure Date</span>
                                    <ExpandMoreIcon style={{ color: "white" }} />
                                </div>
                                {this.state.startDate &&
                                    <span className="calenderDay">{this.selectDepartureDate(this.state.startDate)}</span>
                                }
                            </div>

                        )}
                        onClick={(event) => this.togglePicker('startDate',event)}

                        data-test-id="startDateTestId"
                        placeholder={this.state.startDate ? "" : "Departure date"}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            classes: {
                                root: "custom-input-root",
                                input: "catalogue-header-box",
                            },

                        }}
                        InputLabelProps={{
                            className: "fontLabel",
                            shrink: true,
                            style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
                        }}
                    />

                </Grid>
                {this.calenderBoxUiChild()}
                {(this.state.selectedTripType === "Round Trip") &&
                    <Grid item xs={12} sm={5} md={3} lg={2} style={{ position: "relative" }}>
                        <TextField
                            disabled={this.state.selectedTripType !== "Round Trip"}
                            className="catalogue-header-box"
                            name="returnDate"
                            label={(
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%' }}>
                                        <span className="catalogue-heading">Return Date</span>
                                        <ExpandMoreIcon style={{ color: "white" }} />
                                    </div>
                                    {this.state.endDate1 &&
                                        <span className="calenderDay">{this.selectDepartureDate(this.state.endDate1)}</span>
                                    }
                                </div>
                            )}
                            onClick={(event) => this.togglePicker('endDate',event)}
                            data-test-id="endDate"
                            placeholder={this.state.endDate1 ? "" : `Return date
            `}
                            variant="outlined"
                            fullWidth

                            InputProps={{
                                classes: {
                                    root: "custom-input-root",
                                    input: "catalogue-header-box",
                                },

                            }}
                            InputLabelProps={{
                                className: "fontLabel",
                                shrink: true,
                                style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
                            }}
                        />
                    </Grid>}
                <Grid item xs={12} sm={10} md={4} lg={2} style={{ overflow: "hidden" }}>
                    <TextField
                        name="travellers & class"
                        className="catalogue-header-box"
                        label={(
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%' }}>
                                    <span className="catalogue-heading">Travellers & Class</span>
                                    <ExpandMoreIcon style={{ color: "white" }} />
                                </div>
                                {this.travelInputText()}
                            </div>

                        )}

                        placeholder={"Select no.of travellers & Class."}
                        variant="outlined"
                        fullWidth
                        value={""}
                        data-test-id="handleClickClass"
                        onClick={this.handleClickClass}
                        style={{ width: "100%" }}
                        InputProps={{
                            classes: {
                                root: "custom-input-root",
                                input: "catalogue-header-box",
                            },
                        }}
                        InputLabelProps={{
                            className: "fontLabel",
                            shrink: true,
                            style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
                        }}
                    />
                    <Menu
                        marginThreshold={72}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        data-test-id="handleCloseClass"
                        className="menuFrom"
                        id="simple-menu"
                        anchorEl={this.state.anchorElClass}
                        PaperProps={{
                            style: {
                                width: 600,
                                borderRadius: "10px"
                            }
                        }}
                        open={Boolean(this.state.anchorElClass)}
                        onClose={this.handleCloseClass}
                        keepMounted

                    >

                        <Box style={{ padding: "10px" }}>
                            <Box className="classTravelHead">
                                <Typography style={{ fontSize: "12px", color: "#000", fontWeight: 700 }} variant="body1">{this.state.countAdult} Adult , {this.state.countChild} Children, {this.state.countInfant} Infant</Typography>
                                <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", }} variant="body1">{this.state.travelStatus}</Typography>
                            </Box>
                            <Box style={{ width: "100%", borderBottom: "1px solid rgba(226, 232, 240, 1)", marginTop: "10px" }}></Box>
                            <Box style={{ width: "100%", }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>
                                        <Box sx={{ padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" }, textAlign: "center" }}>
                                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">  {this.state.countAdult} Adults</Typography>
                                            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Aged 18+years) </Typography>
                                            <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                                <button className="borderNoneCount" data-test-id="decreaseCount" onClick={this.decreaseCount}><RemoveIcon /></button>
                                                <div className="countShowText">{this.state.countAdult}</div>
                                                <button className="borderNoneCount" data-test-id="increaseCount" onClick={this.increaseCount}><AddIcon /></button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">  {this.state.countChild} Children</Typography>
                                            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Aged 2-12 years)  </Typography>
                                            <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                                <button className="borderNoneCount" data-test-id="decreaseCountChild" onClick={this.decreaseCountChild}><RemoveIcon /></button>
                                                <div className="countShowText">{this.state.countChild}</div>
                                                <button className="borderNoneCount" data-test-id="increaseCountChild" onClick={this.increaseCountChild}><AddIcon /></button>

                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">  {this.state.countInfant} Infant</Typography>
                                            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Under 2 years)  </Typography>
                                            <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                                <button className="borderNoneCount" data-test-id="decreaseCountChildInfant" onClick={this.decreaseCountChildInfant}><RemoveIcon /></button>
                                                <div className="countShowText">{this.state.countInfant}</div>
                                                <button className="borderNoneCount" data-test-id="increaseCountChildInfant" onClick={this.increaseCountChildInfant}><AddIcon /></button>

                                            </Box>
                                        </Box>
                                    </Grid>

                                    {
                                        this.state.error &&
                                        <Grid item sm={12}>
                                            <Box className="ErrorMsg" sx={webStyleLanding.errorMsgforCount}>
                                                <span className="imgError" style={{ width: "5%", marginRight: "5px" }}>
                                                    <React.Suspense fallback={<CircularProgress />}>
                                                        <img src={errorIcon} />
                                                    </React.Suspense>
                                                </span> <span style={{ width: "95%" }}>
                                                    If you want to add more than 9 travellers please contact email <span style={{ textDecoration: "underline" }}>www.doolwa.com</span>
                                                </span>
                                            </Box>
                                        </Grid>
                                    }

                                    <Box sx={{
                                        width: "100%", textAlign: "center", padding: "0px 0 0px 0",
                                    }}>
                                        <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1"> Travel Class</Typography>
                                    </Box>
                                    <Grid item sm={12}>
                                        {this.allButtonList()}
                                    </Grid>

                                    <Box className="buttonBoxapply" style={{ padding: "10px", margin: "0px 10px" }}>
                                        <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleCancelClassTravel" onClick={this.handleCancelTravel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleDone" onClick={this.handleDone}>&nbsp;Done&nbsp;</Button>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Menu>
                </Grid>
            </div>
        )
    }

    renderPriceModal = () => {
        const formattedData = (dataBg: any) => {
            const { heading, cleared_content } = this.SeparateBullets(dataBg[0]);
            const listItems = cleared_content.map((item, index) => (
                <ListItem 
                key={index} 
                style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', paddingLeft: '0px' }}
            >
                <img src={Included} alt="Bullet point icon" />
                <Typography style={{fontWeight: 500, fontSize: "14px"}}>
                    {item}
                </Typography>
            </ListItem>
            ));
            return [heading, listItems];
        };
        return (
            <Modal
            data-test-id = "view-price-modal"
                open={this.state.priceModal}
                onClose={this.handleClosePriceModal}>
                <Box  sx={Modalstyle.modal} >
                    <Box className="price-head-container" style={{ boxShadow: "0px 2px 4px 0px #0000001A", paddingLeft: "55px", paddingRight: "30px", paddingTop: '40px', paddingBottom: '29px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="price-options-heading " style={{ fontSize: '24px', fontWeight: "700" }}>{configJSON.moreFairRoundTrip} </Typography>
                        <div data-test-id = "viewPriceModalClose" style={{ cursor: 'pointer' }} onClick={this.handleClosePriceModal}> <Close /></div>
                    </Box>
                    <Box className="option-container" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '32px', paddingTop: '32px', paddingRight: '32px' }}>
                        <Typography className="flight-details-head" style={{ fontWeight: "700", paddingLeft: '23px', paddingBottom: "40px", fontSize: "24px", display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>{this.state.searchInput.origin} - {this.state.toText.state} - {this.state.searchInput.origin} </Typography>
                        <Grid container  >
                            <div className="" style={{
                                display: "flex",
                                overflowX: "auto",
                                gap: "8px",
                                paddingBottom: '24.65px', paddingLeft: '10px', paddingRight: '10px',alignItems:"stretch"
                            }}>
                                {this.state.selectedFlightPrice.view_prices.map((item: ViewPrices, indexNumber: number) => (
                                    <div data-test-id = "selectoption" onClick={() => this.handleFareOptionSelect(indexNumber)} className={this.state.fareOptionSelected === indexNumber ? "selectedOption" : ""}>
                                        <Grid className="option-card" key={indexNumber} style={{ boxShadow: "0px 4px 10px 0px #00000029", width: '446px', borderRadius: '8px', cursor: 'pointer', flex: "0 0 auto",height:'100%' }} >
                                            <div className="price-block" style={{ borderBottom: '1px solid #94A3B8', paddingLeft: '24px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                <Typography className="text" style={{ display: 'flex', gap: '6px', fontSize: '16px', fontWeight: '700' }}>{item.currency_type} {item.adult_per_price}  <Typography className="text" style={{ fontWeight: "500", fontSize: '14px', color: '#64748B' }} data-test-id = "selectcardText">per adult</Typography></Typography>
                                                <Typography className="option-type" style={{ fontWeight: '700', fontSize: '14px', textTransform: 'uppercase' }}>{item.BrandName}</Typography>
                                            </div>
                                            <div className="option-details-container" style={{ paddingLeft: '24px', paddingBottom: '20px',height:'auto',display:'flex',flexDirection:'column' }}>
                                                <div style={{ display: 'flex', marginTop: "24px", justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', }}>
                                                    <Typography style={{ fontWeight: 700, fontSize: "16px" }}>{formattedData(item.BaggageInfo)[0]}</Typography>
                                                    <List  id="option-modal" className="medialContent">
                                                        {formattedData(item.BaggageInfo)[1]}</List>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '24px' }}>
                                                    <Button data-test-id = "navigationMenu" onClick={() => this.bookFlight(item,indexNumber)} className="book-now-btn" variant="contained" style={{ height: '56px', backgroundColor: '#1764A0', color: 'white', fontWeight: '600', fontSize: '16px' }}>{this.state.priceClickIndex === indexNumber ? (
                                                            <>
                                                                <CircularProgress size={24} color="inherit" style={{ marginRight: '8px', textTransform: "capitalize" }} />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Book now"
                                                        )}</Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Box>

                </Box>
            </Modal>
        )
    }; 
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        
        const { datePrices, enableSearch,currentPage, itemsPerPage } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = this.state.roundTripResponse.slice(indexOfFirstItem, indexOfLastItem);
        // Customizable Area End
        return (
            //Merge Engine DefaultContainer
            // Customizable Area Start
            <><Loader loading={this.state.loading} />
                <div style={{ background: '#E9F3FC', height: '100%', overflowY: 'scroll' }}>
                    <Box sx={webStyle.headerContainer} data-test-id='header-menu'>
                        <HeaderMenuBlock navigation={this.props.navigation} id={""} />
                        <Grid container spacing={0} className="searchFrom searchInput" style={{ position: "relative", paddingLeft: '10px', paddingRight: "10px" }}>
                            {this.getFlightTypeHeader()}
                            <Grid item xs={12}>
                                <Box style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                                    <Button
                                        onClick={() => this.handleSearch()}
                                        type="submit"
                                        variant="contained"
                                        data-test-id="search-btn"
                                        style={{
                                            backgroundColor: '#1764A0',
                                            color: "white" ,
                                            width: '327px',
                                            height: '56px',
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: '700',
                                            fontSize: '16px',
                                            textTransform: 'none'
                                        }}
                                    >
                                        Search Flights
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>

                    <Box sx={webStyle.contentContainer}>
                        <div className="content-container" style={{
                            position: 'absolute',
                            top: '-100px',
                            width: '100%',
                            display: 'flex',
                            gap: '21px',
                            justifyContent: 'space-around',
                        }}>
                            <Button className="filter-icon-btn " data-test-id="filter-icon" style={{ display: 'none' }} onClick={() => this.handleFiltersToggle()}>
                                <Tune className="catalogue-heading" />
                            </Button>
                            <div className="desktop-filter" data-test-id="filter-block"><Filteritems
                             inputCityFrom ={this.state.searchText.state}
                             clearAllFunct={this.clearAllFunctRound}
                             selectedTripType={"roundTrip"}
                             inputCityTo ={this.state.toText.state}
                            departureTimePrice={this.state.departureTimePrice}
                            airlinsFunct={this.airlinsFunct}
                            airLinesData={this.state.airLinesData}

                            handleDepartureTimeSelect={this.handleDepartureTimeSelect}
                            minPrice={this.state.minPrice}
                            maxPrice={this.state.maxPrice}
                           

                            handleArrivalTimeSelect={this.handleArrivalTimeSelect}
                            arrivalTimePrice={this.state.arrivalTimePrice}
                            timePriceArray={this.state.timePriceArray}

                            shownPopularFilters={this.state.shownPopularFilters}

                            selectedPopularFilter={this.state.selectedPopularFilter}
                              nonStopFunct = {this.handlePopulerFunct}
                            onPriceRangeChange={this.handlePriceRangeChange}
                                
                                selectedPrice={this.state.selectedPrice}
                                
                                price_range={this.state.price_range} navigation={this.props.navigation} id={""} /></div>
                            <Box sx={webStyle.detailsContainer} className="details-container">
                            <Typography className="flight-detail-heading" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700, fontSize: '32px', color: 'white' }}>Flights from {this.state.selectAirportFrom.Code} to {this.state.selectAirportTo.Code} and back</Typography>
                                <Box data-test-id="flights-list-Card" style={{ width: '100%' }}>
                                {currentItems.length===0? <NotFound />:<>
                                    {currentItems.map((item:FlightList , indexNumber: number) => (
                                        this.getRoundTripFlightsList(item, indexNumber)
                                    ))}

<div className="pagination-container">
          <Pagination
          data-test-id="pagination"
            count={Math.ceil(this.state.roundTripResponse.length / itemsPerPage)}
            page={this.state.currentPage}
            onChange={this.handlePageChange}
            variant="outlined"
            color="primary"
          />
        </div>
                                    </>
                                    
                                    }
                                </Box>

                              
                            </Box>
                        </div>
                    </Box>
                    {this.renderPriceModal()}
                    <Drawer
                        PaperProps={{
                            style: {
                                height: '90vh'
                            },

                        }}
                        anchor={"bottom"}
                        variant="temporary"
                        open={this.state.filtersDrawer}
                        onClose={() => this.handleFiltersToggle()}

                        data-test-id="filters-drawer"
                    >

                        <div data-test-id="filter-block">
                            
                            <Filteritems
                            departureTimePrice={this.state.departureTimePrice}
                            inputCityFrom ={this.state.searchText.state}
                            clearAllFunct={this.clearAllFunctRound}
                            inputCityTo ={this.state.toText.state}
                            selectedTripType={"roundTrip"}
                         airlinsFunct={this.airlinsFunct}
                        airLinesData={this.state.airLinesData}





                        handleDepartureTimeSelect={this.handleDepartureTimeSelect}
                        handleArrivalTimeSelect={this.handleArrivalTimeSelect}

                          onPriceRangeChange={this.handlePriceRangeChange}
                            selectedPrice={this.state.selectedPrice}
                            minPrice={this.state.minPrice}
                            maxPrice={this.state.maxPrice}
                        arrivalTimePrice={this.state.arrivalTimePrice}
                        timePriceArray={this.state.timePriceArray}
                        shownPopularFilters={this.state.shownPopularFilters}

                            selectedPopularFilter={this.state.selectedPopularFilter}
                              nonStopFunct = {this.handlePopulerFunct}
                            data-test-id="handlePriceRangeChangeRoundTrip"
                           
                            
                            price_range={this.state.price_range} navigation={this.props.navigation} id={""} /></div>
                    </Drawer>
                </div>
            </>
            // Customizable Area End
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start

const RoundTripContainer = styled(Box)({
        display: "flex",
        justifyContent: "space-between",
        padding: "25px 10px 0px 10px",
        flexWrap:"wrap",
        "@media screen and (min-width: 830px) and (max-width: 1226px)": {
          padding: "15px 10px 0px 10px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
          padding: "25px 10px 0px 10px",
        },
        "@media screen and (min-width: 601px) and (max-width: 700px)": {
          padding: "15px 10px 0px 10px",
        },
        "@media screen and (min-width: 360px) and (max-width: 414px)": {
          padding: "15px 10px 0px 10px",
          display: "block",
        },

      "& .viewPrices": {
        color: 'white',
        background: '#1764A0',
        padding: '10px, 12px, 10px, 12px',
        textTransform: 'none',
        borderRadius: '8px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 600,
        fontSize: '14px'
    },
      "& .multiAirLince": {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#000000",
        "@media screen and (min-width: 360px) and (max-width: 600px)":{
          fontSize: "14px",
        },
        "@media screen and (min-width: 601px) and (max-width: 800px)":{
            fontSize: "14px",
          },
      },
      "& .roundMarginTop": {
        marginTop: "16px",
        "@media screen and (min-width: 360px) and (max-width: 600px)":{
          marginTop: "10px",
        },
      },
      "& .roundFlightPrice": {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#000000",
        "@media screen and (min-width: 360px) and (max-width: 600px)":{
          fontSize: "14px",
          marginTop: "10px"
        },
        "@media screen and (min-width: 601px) and (max-width: 800px)":{
            fontSize: "13px"
          }
      },
      "& .roundFlightName": {
        fontFamily: "inherit",
        fontWeight: 500,
        fontSize: "24px",
        color: "#000000",
        "@media screen and (min-width: 601px) and (max-width: 800px)":{
          fontSize: "20px",
        },
        "@media screen and (min-width: 360px) and (max-width: 414px)":{
          fontSize: "16px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)":{
          fontSize: "18px",
        }
      },
      "& .timeDurationContainer":{
          display: "flex",
          justifyContent: "center",
          alignItems:"center",
          border: "1px solid #1764A0",
          borderRadius: "100px",
          maxHeight: "40px",
          width: "189px"
      },
      "& .timeDuration": {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "18px",
        color: "#64748B",
        alignItems: "center",
        textAlign: "center",
        boxSizing: "border-box",
      },
      "& .roundFlightNumber" : {
         fontSize: "16px",
         fontWeight: 600,
         fontFamily: "Inter",
         lineHeight: "18px",
         color:"#000000"
      },
      "& .priceButton": {
        background: "#1764A0",
        color: "#FFFFFF",
        width: "103px",
        height: "40px",
        borderRadius: "8px",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "24px",
        cursor: "pointer",
        textTransform: "capitalize",
        textAlign:"center",
        "@media screen and (min-width: 360px) and (max-width: 600px)":{
          marginTop: "10px",
          width: "103px",
          height: "40px",
        },
        "@media screen and (min-width: 601px) and (max-width: 800px)":{
            fontSize: "12px",
            width: "95px",
            height: "27px",
            marginTop:" 3px"
          },
      },
});


const DividerContainer = styled(Divider)({
    background: "#1764A0",
    margin: "40px 0px",
    "@media screen and (min-width: 300px) and (max-width:414px)":{
        margin: "20px 0px",
      }
});

const Modalstyle = {
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "95%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: "8px"
    },
    heading: {
        fontSize: '16px',
        fontWeight: '700',
        paddingBottom: '32px',
        paddingTop: '24px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '19px'
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'start',
        gap: '16px',

    },
    itemContainer: {
        display: "flex",
        overflowX: "auto",
        gap: "8px",
    }
};

const webStyle = {
    textFlightd: {
        fontFamily: "Inter, sans-serif",
        fontSize: '12px',
    },
    productContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: "white",
        marginTop: "20px",
    },
    priceBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '-2px'
    },
    priceText: {
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '14px',
    },
    timePriceCard: {
        border: '2px solid #64748B',
        borderRadius: '16px',
        width: '90px',
        height: '90px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px', alignItems: 'center',
        justifyContent: 'center'
    },
    timePriceContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '10px',
    },
    timeText: {
        fontFamily: 'Inter, sans-serif',
        fontWeight: '600',
        fontSize: '14px',
        color: '#94A3B8'
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "56px",
        overFlowX: 'hidden',
        height: {
            xs: '930px',
            sm: '800px',
            md: '580px',
            lg: '490px'
        },
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    },
    secondHeading: {
        fontFamily: "Inter, sans-serif",
        fontWeight: '700',
        fontSize: '24px',
    },
    heading: {
        fontFamily: "Inter, sans-serif",
        fontWeight: '700',
        fontSize: '24px',
        marginLeft: '32px',
        marginTop: '32px'
    },
    oneWayHeading: {
        fontFamily: "Inter, sans-serif",
        fontWeight: '700',
        fontSize: '24px',
        marginBottom: '-4px'
    },
    sliderContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",
        width: "100%",
        height: '100%',
        background: "#E9F3FC",
        gap: '21px',
        position: 'relative'

    },
    errorMsgforCount: {
        marginBottom: "20px",
        borderRadius: "5px",
        fontSize: "12px",
        borderLeft: "6px #c10f0ff0 solid",
        background: " #ed7e7e3d",
        fontFamily: "Inter, sans-serif",
        color: "#c12121",
        display: "flex",
        lineHeight: "24px",
        padding: "20px 15px",
        alignItems: "center"
    },
    detailsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: '10px',
        flexWrap: "wrap",
        width: "60%",
        height: '100%',
        background: "transparent",
        borderRadius: '8px',
        marginRight: '21px'
    },
    productBox: {
        height: 250,
        width: "49%",
        marginRight: "5px",
        flexDirection: "column",
    },
    popularFilterBox: {

    },
    slider: {
        color: '#1764A0',
        height: 8,
        '& .MuiSlider-thumb': {
            height: 32,
            width: 32,
            backgroundColor: '#fff',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&::before': {
                display: 'none',
            },
        },
    },
    ImgContainer: {
        //marginBottom: 15,
        height: 150,
    },
    productImg: {
        width: "100%",
        height: "100%",
    },
    detailContent: {
        display: "flex",
        flexDirection: "column",
    },
};
const flightItemStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        borderRadius: '5px',
        marginBottom: '10px',
        background: 'white',
        boxShadow: '0px 4px 10px 0px #00000029'

    },
    bottomRow1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '5',
        paddingBottom: '5',
    },
    topRow1: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '32',
        paddingTop: '20',
        paddingBottom: '20',
        paddingRight: '24'
    },
    durationColumn: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'flex-start',
        gap: '16px'
    },
    topRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '2px dashed #1764A0',
        paddingLeft: '32',
        paddingTop: '20',
        paddingBottom: '20',
        paddingRight: '24'
    },
    bottomRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '24',
        paddingTop: '14',
        paddingBottom: '14',
        paddingRight: '25'
    },
    flightHeading: {
        fontFamily: "Inter, sans-serif",
        fontWeight: '700',
        fontSize: '16px'
    },
    column: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'flex-start',
        gap: '16px'
    },
    flightDetails: {
        display: 'flex',
        alignItems: 'center',
    },
    flightLogo: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
        borderRadius: '50%',
    },
    priceDetails: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'flex-start',
        gap: '16px'
    },
    placeText: {
        fontFamily: "Inter, sans-serif",
        fontWeight: '600',
        fontSize: '16px',
        color: '#64748B'
    }
};
const webStyleLanding = {
    errorMsgforCount: {
        margin: "0px 10px",
        borderRadius: "5px",
        fontSize: "12px",
        borderLeft: "6px #c10f0ff0 solid",
        background: " #ed7e7e3d",
        fontFamily: "Inter, sans-serif",
        color: "#c12121",
        display: "flex",
        lineHeight: "24px",
        padding: "20px 15px",
        alignItems: "center"
    },
}
// Customizable Area End