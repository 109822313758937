import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appliedCoupon:any;
  selectedPrice: number,
      minPrice: number,
      maxPrice: number,
      price_range: {
        min: number,
        max: number
      },
      minDuration: number;
      maxDuration: number;
      selectedDuration: number;
      dataAllPackage:any;
      ErrorMsg:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllPackageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ErrorMsg:"",
      dataAllPackage:[],
      selectedPrice: 50,
      minPrice: 0,
      maxPrice: 100,
      price_range: {
        min: 0,
        max: 0
      },
      minDuration: 2,  
      maxDuration: 7,   
      selectedDuration: 2,
      appliedCoupon:null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

 
  handlePriceChange = (event:any, newValue:any) => {
    this.setState({ selectedPrice: newValue });
  };
  handleDurationChange = (event:any, newValue:any) => {
    this.setState({ selectedDuration: newValue });
  };
  handleChildDataFn = (data:any, count:any) => {
    if(data === "Package is not found"){
      this.setState({ErrorMsg:data,dataAllPackage:[]})

    }else{
      let dataAll = data.map((item:any)=>{
        return {
          "image":item.attributes.image,
          "name":item.attributes.name,
          "price": item.attributes.price,
          "totalprice": item.attributes.price * count,
          "package_type": item.attributes.package_type,
        }
      })
      this.setState({dataAllPackage:dataAll,ErrorMsg:""})
    }
   
};

  // Customizable Area End
}
