import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Toolbar,
    AppBar,
    Grid,
    IconButton,
    Drawer,
    ListItem,
    ListItemText,
    MenuItem,
    Popover
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeaderMenuController, { Props } from "./HeaderMenuController.web";
import { logo, userIcon,logout } from "./assets";

import MenuIcon from '@material-ui/icons/Menu';
import "../assets/style.css"
interface MenuType{
    "label":string;
    "href":string;
}
const menuItems = [
    { label: 'Home', href: 'Home' },
    { label: 'My Trip', href: 'MyTrips' },
    { label: 'Offers', href: 'ViewallOffers' },
    
];
  // Customizable Area End
  // Customizable Area Start
export default class HeaderMenuBlock extends HeaderMenuController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box style={{ position: "relative",width:'100%' }}>
            <Box style={{ zIndex:-1 }}>
                    <AppBar className="AppBarHeader" position="static" style={{ backgroundColor: "transparent", boxShadow:"none",}}>
                        <Toolbar>
                            <Typography variant="h6" style={{ flexGrow: 1, cursor:"pointer" }}>
                                <Box>
                                    <img data-test-id="handleHome" onClick={()=>this.handleHome("Home")} src={logo} alt="logo" />
                                </Box>
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                data-test-id="handleMenuToggle"
                                onClick={this.handleMenuToggle}
                                className="displatHeader"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="right"
                                open={this.state.menuOpen}
                                onClose={this.handleMenuToggle}
                            >
                                <Box
                                    sx={{ width: 250 }}
                                    role="presentation"
                                    onClick={this.handleMenuToggle}
                                    onKeyDown={this.handleMenuToggle}
                                >
                                    <Box p={2}>
                                        {menuItems.map((item:MenuType, index:number) => (
                                            <ListItem
                                            button
                                            data-test-id ={`nav-links${index}`}
                                            key={index}
                                            onClick={()=>this.handleHome(item.href)}
                                            style={{ color: "#000", fontWeight: 700, fontSize: "20px", marginBottom: "10px" }}
                                          >
                                            <ListItemText primary={item.label} />
                                          </ListItem>
                                        ))}
                                         {this.state.authToken!=""?<>
                                         <ListItem
                                            button
                                            href="#"
                                            style={{ color: "#000", fontWeight:700, fontSize: "20px", marginBottom: "10px" }}
                                          >
                                            <ListItemText primary={this.state.userName}  />
                                          </ListItem>
                                          <ListItem
                                            button
                                            onClick={this.logoutFunct}
                                            href="#"
                                            style={{ color: "#000", fontWeight:700, fontSize: "20px", marginBottom: "10px" }}
                                          >
                                            <ListItemText primary={"Logout"}  />
                                          </ListItem>
                                         
                                         </>:<>
                                         <ListItem
                                            button
                                            data-test-id="loginRedirect"
                                            onClick={this.loginRedirect}
                                            href="#"
                                            style={{ color: "#000", fontWeight: 700, fontSize: "20px", marginBottom: "10px" }}
                                          >
                                            <ListItemText primary={"Login / Sign Up"} />
                                          </ListItem>
                                         </>
    }
                                         
                                    </Box>
                                </Box>
                            </Drawer>
                            
                            <Grid ref={this.scrollRef} container justifyContent="flex-end" alignItems="center"
                            className="menuItemHeader"
                            > 
                                {menuItems.map((item:MenuType, index:number) => (
                                    <Grid item key={index}>
                                        <Button data-test-id={`nav-btn${index}`} className="textItemHesader" onClick={()=>this.handleHome(item.href)} style={{ color: "#FFFFFF", fontWeight:700, fontSize: "20px", textTransform:"capitalize" }}>{item.label}</Button>
                                    </Grid>
                                ))}
                                
                                {this.state.authToken!=""?<>
                                    <Button data-test-id="buttonUser" className="textItemHesader" style={{ color: "#FFFFFF", fontWeight:700, fontSize: "20px", textTransform: "capitalize" }} onClick={this.handleMenuOpen}>
                                {this.state.userName} <ExpandMoreIcon />
                                
                            </Button>
                            <Popover

                                anchorEl={this.state.anchorEl}
                                open={Boolean(this.state.anchorEl)}
                                data-test-id="buttonClose"
                                onClose={this.handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem data-test-id = "profile-menu" onClick={this.handleProfile} style={webHeaderStyle.userDrop}><img src={userIcon}/> &nbsp;Profile</MenuItem>
                                <MenuItem data-test-id="logoutBox" style={webHeaderStyle.userDrop} onClick={this.logoutFunct}><img src={logout}/> &nbsp;Logout</MenuItem>
                            </Popover>
                                </>:
                                 <Grid item >
                                 <Button data-test-id="loginRedirect" onClick={this.loginRedirect} className="loginButton textItemHesader" href="#" style={{ color: "#FFFFFF", fontWeight:700, fontSize: "16px", textTransform:"capitalize" }}>{"Login/Signup"}</Button>
                             </Grid>
                                }
                               
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box style={{borderBottom:"1px #fff solid"}}></Box>
                </Box>
        </Box>
        )
    }
}
  // Customizable Area End

  // Customizable Area Start
  const webHeaderStyle = {
    userDrop:{
        width: '300px', padding: '15px 20px', borderBottom:"1px rgb(226, 232, 240) solid", fontSize:"16px"
    }
  }
  // Customizable Area End

