import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appliedCoupon: any;

  offersData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewAllOfferPackageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  offerApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      offersData: [],
      appliedCoupon: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(

        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.offerApiCallId && this.offerApiCallId != null) {
          this.GetInAllOffersList(responseJson);
        }

      }
    }
    // Customizable Area End
  }

  async componentDidMount() {

    this.offerlistDataApi();

  }
 
  handleChildData = (data: any) => {
    data.map((item: any) => {
      return {
        "package_type": item.attributes.package_type,
        "name": item.attributes.name,
        "image": item.attributes.image,
        "price": item.attributes.price,
        
      
      }
    })
  };
  GetInAllOffersList = (responseJson: any) => {
    if (responseJson.data.length > 0) {
      let dataYY = responseJson.data.map((item: any) => {
        return {
            "id":item.id,
            "image": item.attributes.image,
            "title": item.attributes.title,
            "offer_code": item.attributes.offer_code,
      
        }
      })
      this.setState({ offersData: dataYY })
    }
  }
  offerlistDataApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.offerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetInOffers
    );

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  truncateString = (str:string, maxLength:number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };
  handleOffersDetails = (idOfferss:string) => {
     this.props.navigation.navigate("SingleOffers", { id: idOfferss})
   };
  // Customizable Area End
}
