// Customizable Area Start
import React,{Suspense} from "react";
import BlogPostsManagementController, { Props,BlogSectionObj,BlogImage  } from "./BlogPostsManagementController";
import { Box, Grid, Typography, CircularProgress } from "@material-ui/core";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import Footer from "../../../components/src/Footer";
import "../assets/style.css"
import OfferBanner from "../../../components/src/OfferBanner"
import ImageWithSkeleton from "../../../components/src/ImageWithSkeleton";
const webStyleLanding={
  backgroundImage: {
      
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100%",
      opacity: 2.5,
    },
    imageStyle: {
      width: "100%",
      height: "300px",
      borderRadius: "15px"
    }
}

export default class BlogpostsManagement extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
      <div style={{marginTop: "-10px"}} >
      <Suspense fallback={<CircularProgress />}>
      <Box
      data-test-id = "Header-container"
  className="offerH2Titler"
  sx={{
    position: 'relative',
    overflow: 'hidden',
  }}
>
  <OfferBanner data-test-id="oceanbackground" oceanbackground={this.state.singleblogData.data.attributes.image} />
  <HeaderMenuBlock navigation={this.props.navigation} id={""} />
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: { xs: "200px", sm: "250px", md: "450px" },
      position: 'relative',  
      zIndex: 1, 
    }}
  >
    <Typography
      className="blogH2Title"
      style={{color:"#fff",padding:"10px",fontSize:"48px",fontWeight:"700",fontFamily: 'Inter, sans-serif',}}
      variant="h2"
    >
      {this.state.singleblogData.data.attributes.name}
    </Typography>
  </Box>
</Box>
     </Suspense>
     <Box key="static-box" sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"40px 60px" }}}>
         <Box sx={{padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"10px 120px" },marginTop:{
          xs:"0",sm:"0",md:"0",lg:"-20px"
         }}}>
         <Typography className="blogPTitle" style={{textAlign:"center",color:"rgba(0, 0, 0, 1)", fontWeight:"300", lineHeight:"36px", fontSize:"24px",fontFamily: 'Inter, sans-serif',}} variant="body1" dangerouslySetInnerHTML={{ __html: this.state.singleblogData.data.attributes.body }} />

         </Box>
           {
               this.state.singleblogData.data?.attributes?.blog_sections?.data?.map((item:BlogSectionObj)=>(
                   <>
                       <Box key={item.id}>
                       <Typography className="blogH2Title" style={{ textAlign:"center",color:"#000", fontWeight:"700", lineHeight:"36px", fontSize:"32px",fontFamily: 'Inter, sans-serif',}} variant="body1" dangerouslySetInnerHTML={{ __html: item.attributes.sub_title }} />
                       {item.attributes.description && 
                       <Box sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"40px 250px" },marginTop:"20px" }}>
                         <Typography className="blogPTitle" style={{color:"rgba(0, 0, 0, 1)", fontWeight:"300", lineHeight:"36px", fontSize:"24px",fontFamily: 'Inter, sans-serif', textAlign:"center"}} variant="body1" dangerouslySetInnerHTML={{ __html: item.attributes.description }} />
                       </Box>
                         }
                      </Box>
                       {
                           item.attributes.blog_images?.data?.map((chieldItem:BlogImage)=>(
                          <Box key={item.id} sx={{boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
                           borderRadius: "15px",
                           margin:" 50px 0",
                           width: "100%",}}>
                          <Box sx={{padding:"20px"}}>
                          <Grid container spacing={4} style={{alignItems:"center"}}>
                               <Grid xs={12} sm={12} md={4} lg={4} item>
                                   <Box>
                                       <Suspense fallback={<CircularProgress />}>
                                       <div className="offerimgSizeheight">
                                        <ImageWithSkeleton src={chieldItem.attributes.image} type={"blogPage"} />
                                       </div>
                                       </Suspense>
                                   </Box>
                               </Grid>
                               <Grid xs={12} sm={12} md={8} lg={8} item>
                                   <Box className="liboxP">
                                       <Typography className="typoMrgin0" style={{margin:"20px 0",color:"rgba(0, 0, 0, 1)", fontWeight:"700", lineHeight:"30px", fontSize:"24px",fontFamily: 'Inter, sans-serif',}} variant="h4">{chieldItem.attributes.title}</Typography>
                                       <Typography style={{color:"rgba(0, 0, 0, 1)", fontWeight:"400", fontSize:"16px",fontFamily: 'Inter, sans-serif',}} variant="h4" dangerouslySetInnerHTML={{ __html: chieldItem.attributes.description}}/>
                                   </Box>
                               </Grid>
                           </Grid>
                          </Box>
                       </Box>
                           ))
                       }
                       
                   </>
               ))
           }
     </Box>
     <Footer />
     </div>
     </>
      // Customizable Area End
    );
  }
}
// Customizable Area End
