import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { RefObject } from 'react';
import { imgPasswordInVisible, imgPasswordVisible,wallet,profileicon,logout ,deleteIcon, } from "./assets";
import { baseURL } from "../../cfitinerarymanagement/src/Cfitinerarymanagement.web";
export interface UserProfileDetails{
  status: string, 
  icon: string
}
export interface CountryType{
  "data": CountryArry[]
}
export interface ZipType{
  "country":string,"state":string,"city":string,
  "error":[{"msg":string}]
}
export interface DeleteRes{
  errors: {token:string},
  message: string
}
export interface CountryArry{

  "id": string,
  "type": string,
  "attributes": {
      "name": string,
      "emoji_flag":string,
      "country_code": string
  }
}
export interface DateRange {
  selection: {
    startDate: Date;
    endDate: Date;
  };
}

interface UserProfile {
  errors: {token: string}[] | string[]
  data: {
    id: string;
    type: string;
    attributes: {
      image: string| null
      activated: boolean;
      country: string | null;
      email: string;
      phone_number: string | null;
      gender: string | null;
      date_of_birth: string | null;
      address1: string | null;
      address2: string | null;
      passport_expiry_date: string | null;
      passport_number: string | null;
      zip_code: string | null;
      city: string | null;
      state: string | null;
      country_code: string | null;
      full_name: string | null
    };
  };
}

interface PasswordResponse {
  message?: string
  errors? :{token: string}[]
}
interface EmailResponse {
  message?: string;
  errors?: { token?: string,otp?: string }[] ;

}


// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  Seconds:number,
  minutes:number,
  email: any;
  userEmail: string;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  profileApiError: string
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  startDate: Date | undefined;
  expirystartDate: Date |undefined;
  expiryendDate: Date | undefined
  endDate: Date | undefined;
  edtEmailEnabled: boolean;
  deletError: string,
  showEmailError: boolean
  state: string,
  inputState: string,
  passportNo: string,
  inputPassportNo: string
  passportExpiry: string
  inputPassportExpiry: string
  profilePic: string
  countryData: CountryArry[]
  contactNum: any
  inputContactNum: any
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  successOtpText: string
  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;
  emailMessageText: string,
  showCalender: boolean
  toastOpen: boolean
  ApiResponseMessage: string
  dateOfBirth: string
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  showPassword: boolean;
  passwordError:string
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  saveButtonDisable: boolean;
  activeItem: string;
  userDetails : Array<UserProfileDetails>;
  fullName: string;
  gender: string;
  inputGender: string
  inputFullName: string
  dateOfBorth: string | null;
  inputDateOfBirth: string
  address: string;
  inputAddress: string
  address2: string,
  inputAddress2: string,
  city: string;
  inputCity: string
  pinCocde: string;
  inputPinCode: string
  country: string;
  inputCountry: string
  countryAnchorEl: HTMLElement | null;
  editProfileModal: boolean;
  changeEmailModal: boolean;
  showExpiryCalender: boolean
  VerifyOtpModal: boolean;
  newPassword: string,
  oldPassword: string,
  confirmPassword: string,
  hasUpperCase: boolean,
  hasLowerCase: boolean,
  hasNumber: boolean,
  deletedAccount:boolean
  hasMinLength: boolean,
  hasMaxLength: boolean,
  emailChangeError: string,
  countryCode: string
  emailBtnDisable: boolean,
  showOtpUi: boolean,
  otpValue: string,
  email_verify: boolean
  changedEmail: string,
  loading: boolean
  errors: {
    name?:string;
    email?: string;
    password?: string;
    confirmPassword?: string;
  };
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  fileInputRef: RefObject<HTMLInputElement>
  interval: NodeJS.Timeout = setTimeout(() => {}, 0);
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  changePasswordApiCallId: string = "";
  updateEmailApiCallId: string = "";
  addOtpApiCallId:string=""
  getUserProfileApiCallId: string =""
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  editUserProfileApiCallId: string ="";
  editImageApiCallId: string = ""
  countryCodeApiCallId: string=""
  zipcodeCodeApiCallId: string = ""
  deleteAccountAPiCallId: string = ""
  userAttr: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.fileInputRef = React.createRef();
    this.state = {
      showCalender: false,
      showPassword: false,
      showOtpUi: false,
      inputCountry: '',
      changedEmail:'',
      successOtpText: '',
      errors: {
        name:"",
        email: "",
        password: "",
        confirmPassword: "",
      },
      emailMessageText: '',
      dateOfBirth: '',
      emailChangeError: '',
      emailBtnDisable: true,
      showExpiryCalender: false,
      deletedAccount:false,
      showNewPassword: false,
      address2:'',
      inputAddress2:'',
      newPassword: '',
      oldPassword:'',
      confirmPassword:'',
      loading: false,
      expiryendDate: undefined,
      expirystartDate: undefined,
      Seconds: 20,
      deletError:'',
      profilePic: '',
      minutes: 0,
      toastOpen:false,
      ApiResponseMessage: '',
      hasUpperCase: false,
      email_verify: true,
      countryData:[],
      state: '',
      inputState: '',
      passportNo: '',
      inputPassportNo: '',
      passportExpiry: '',
      inputPassportExpiry: '',
      contactNum: '',
      countryCode: '',
      inputContactNum: undefined,
      passwordError: '',
      hasLowerCase: false,
      hasNumber: false,
      inputFullName:'',
      inputGender:"",
      inputDateOfBirth:'',
      inputAddress:'',
      inputCity:'',
      inputPinCode:'',
      hasMinLength: false,
      hasMaxLength: false,
      profileApiError: '',
      showConfirmPassword: false,
      firstName: "",
      lastName: "", 
      showEmailError: false,
       startDate: undefined,
      endDate: undefined,
      userEmail: '',
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      otpValue: "",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      activeItem: "Profile",
      userDetails : [{status:'Profile', icon: profileicon}, {status:'Credit Score', icon: wallet}, {status:'Logout',icon: logout}, {status:'Delete Account',icon: deleteIcon}],
      fullName: "",
      gender: "",
      dateOfBorth: "",
      address: "",
      city: "",
      pinCocde: "",
      country: "",
      countryAnchorEl: null,
      editProfileModal: false,
      changeEmailModal: false,
      VerifyOtpModal: false,
    };
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (true) {
        case apiRequestCallId === this.countryCodeApiCallId:
          this.handleGetAllCountryList(responseJson);
          break;
        case apiRequestCallId === this.changePasswordApiCallId:
          this.handlePasswordSuccessRes(responseJson)
          break;
        case apiRequestCallId === this.updateEmailApiCallId:
          this.handleEmailUpdateResponse(responseJson)
          break
        case apiRequestCallId === this.addOtpApiCallId:
          this.handleOtpResponse(responseJson)
          break;
        case apiRequestCallId === this.getUserProfileApiCallId:
          this.handleProfileSuccess(responseJson)
          break;
        case apiRequestCallId === this.editUserProfileApiCallId:
          this.handleUpdateProfileSuccess(responseJson)
          break;
        case apiRequestCallId === this.zipcodeCodeApiCallId:
          this.zipcodeResponse(responseJson)
          break;
        case apiRequestCallId === this.editImageApiCallId:
          this.handleImageSuccess(responseJson)
          break
        case apiRequestCallId === this.deleteAccountAPiCallId:
          this.handleDeleteAccountSuccess(responseJson)
          break
      }
 
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
     onChangeText: (text:string) => 
     {
       if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
     
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
       }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType:"tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps  = {
    value: "",
    editable: true,
    onChangeText: (text:string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps  = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => { 
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ 
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry });
        this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
        this.imgNewPasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText:(text:string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({ 
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry });
        this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
        this.imgReTypePasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }
  async componentDidMount() {
    this.handleGetProfileDetails()
    this.countrylistFunct()
    
  }
  handleGetAllCountryList = (responseJson: CountryType) => {
    this.setState({ countryData: responseJson.data })
  }
  handleMenuItemClick = (item: string) => {
    this.setState({ activeItem: item });
    if(item === "Logout"){
      this.logoutFunction()
    }
  };

  changeFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let {value} = event.target
    this.setState({inputFullName: value});
  };

  changeEmail = (event: React.ChangeEvent<HTMLInputElement>)=>{
    let {value} = event.target
    this.setState({changedEmail:value,emailChangeError: '', email_verify:false,emailBtnDisable: false, showEmailError: false})
  }

  validatedEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  
  changeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({inputAddress: event.target.value});
  };
  changeAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({inputAddress2: event.target.value});
  };

  editProfileCloseModal = () => {
    this.setState({editProfileModal: false,});
  };



  editProfileOpenModal = () => {
    const {city,pinCocde,gender,dateOfBirth,address,address2,passportNo,passportExpiry,country,contactNum,state} = this.state
    this.setState({
      editProfileModal: true, 
      inputFullName: this.state.fullName,
      inputAddress: address === "Add Address"?"":address, 
      inputCity: city === "Add City"?"":city, 
      inputPinCode: pinCocde === "Add Pincode"?"":pinCocde, 
      inputGender: gender === "Add Gender"?"":gender, 
      inputDateOfBirth: dateOfBirth === "Add Date of Birth"?"":dateOfBirth,
      inputAddress2: address2 === "Add Address"?"":address2,
      inputPassportNo: passportNo === "Add Passport Number"?"":passportNo,
      inputPassportExpiry: passportExpiry === "Add Passport Expiry Date"?"":passportExpiry,
      inputCountry: country ==="Add Country"?"": country,
      inputState: state === "Add State"?"":state,
      inputContactNum:contactNum === "Add Phone Number"?"":contactNum,
    })
  };

  changeEmailCloseModal = () => {
    this.setState({
      changeEmailModal: false,
      email_verify:true,
      showEmailError:false,
      emailChangeError:'',
      changedEmail:'',
      showOtpUi:false,
      email:'',
      otpValue:'',
      emailMessageText:'',
      successOtpText:'',
      emailBtnDisable:true});
  };

  changeEmailOpenModal = () => {
    this.setState({changeEmailModal: true});
  };

  verificationOtpCloseModal = () => {
    this.setState({VerifyOtpModal: false,oldPassword:'',passwordError:'',newPassword:'',confirmPassword:''});
  };

  verificationOtpOpenModal = () => {
    this.setState({VerifyOtpModal: true});
  };
  handleDisplayPassword = ()=>{
    this.setState({showPassword: !this.state.showPassword})
  }
  handleDisplayNewPassword = ()=>{
    this.setState({showNewPassword: !this.state.showNewPassword})
  }
  handleDisplayConfirmPassword = ()=>{
    this.setState({showConfirmPassword: !this.state.showConfirmPassword})
  }
  handlePasswordChange =  (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    this.setState({
        newPassword: password,
        hasUpperCase: /[A-Z]/.test(password),
        hasLowerCase: /[a-z]/.test(password),
        hasNumber: /\d/.test(password),
        hasMinLength: password.length >= 8,
        hasMaxLength: password.length <= 32 && password.length !== 0,passwordError:''
    });
  };
  handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({oldPassword: event.target.value,passwordError:''})
  }
  handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({confirmPassword: event.target.value,passwordError:''})
  }
  EmailChangeEnterPress = (event:  React.KeyboardEvent<HTMLInputElement>)=>{
    if(event.key === 'Enter'){
      if(this.state.showOtpUi && (this.state.otpValue && this.state.otpValue.length >= 4)){
        this.handleVerifyOtp()
      }
      else if(!this.state.email_verify){
        this.handleverify()
      }
    }
  }
  handleSubmitNewPassword = () => {
    if(this.state.newPassword !== this.state.confirmPassword){
      this.setState({passwordError:configJSON.PASSWORD_MISMATCH_ERROR})
    }
    else{
    const token = localStorage.getItem("authToken");
    this.setState({loading:true})
    if (token) {
      const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: token
      };
      const body ={
        "data": {
            "old_password": this.state.oldPassword,
            "new_password": this.state.newPassword,
            "password_confirmation" :this.state.confirmPassword
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.changePasswordApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editPasswordChangeEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {this.logoutFunction()}
    }
  };

  setTimerDefault=()=>{
    this.interval=setInterval(()=>{
        const {Seconds}=this.state
        if(Seconds>0){
          this.setState({Seconds:Seconds-1})
        }
        else{
          clearInterval(this.interval)  
        }
      },1000)
  }
  handleEmailUpdateResponse = (responseJson: EmailResponse) => {
    if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token) {
      this.setState({ emailChangeError: responseJson.errors[0].token });
    }
     else if (responseJson.message) {
      if(responseJson.message === "OTP sent to new email"){
        this.setState({showOtpUi: true,emailMessageText: responseJson.message})
        this.setState({Seconds:20}) 
        clearInterval(this.interval);
        this.setTimerDefault()
      }else this.setState({ emailChangeError: responseJson.message,emailMessageText:'',successOtpText:'' });
    } else {
      this.setState({ emailMessageText: "Unexpected response" });
    }
  };
  handleOtpResponse =(responseJson:EmailResponse)=>{
    if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token) {
      this.setState({ emailChangeError: responseJson.errors[0].token });
    }
    else if(responseJson.errors && responseJson.errors.length >0 && responseJson.errors[0].otp){
      this.setState({ emailChangeError: responseJson.errors[0].otp });
    }
    else if (responseJson.message) {
      if(responseJson.message.includes("Invalid") || responseJson.message.includes("invalid"))
        this.setState({ emailChangeError: responseJson.message })
      else {
        this.setState({ 
          ApiResponseMessage: responseJson.message,
          toastOpen: true, 
          changeEmailModal: false,    
          email_verify:true,
          showEmailError:false,
          emailChangeError:'',
          changedEmail:'',
          showOtpUi:false,
          email:'',
          fullName:'',
          otpValue:'',
          emailMessageText:'',
          successOtpText:'',
          emailBtnDisable:true
        });
        this.handleGetProfileDetails()
      }
    } else {
      this.setState({ emailMessageText: "Unexpected response" });
    }
  }
  setPassportExDate(text: string) {
    this.setState({
      inputPassportExpiry:text
    });
  }
  setDob(text:string){
    this.setState({
      inputDateOfBirth: text,
    });
  }
 

 
  handleverify = () => {
    let { changedEmail } = this.state
    if (!this.validatedEmail(changedEmail)) {
      this.setState({ emailChangeError: "Entered Email is invalid", email_verify: true, emailBtnDisable: true, showEmailError: true })
    }
    else {
      this.setState({ emailChangeError: '', email_verify:false,emailBtnDisable: false, showEmailError: false })
      const token = localStorage.getItem("authToken");
      if (token) {
        const header = {
          "Content-Type": configJSON.contentTypeApiGetUserProfile,
          token: token
        };

        const body = {
          "data": {
            "email": changedEmail
          }
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateEmailApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.editEmailchangeEndpoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      } else {

        this.logoutFunction()}
    }
  }
  handleGetProfileDetails = () => {
    const token = localStorage.getItem("authToken");
    const userDataString = localStorage.getItem("userData"); 
    if (token) {
      if (userDataString) { 
        const userData: { id: string; type: string } = JSON.parse(userDataString); 
        const id = userData.id;
  
        const header = {
          "Content-Type": configJSON.contentTypeApiGetUserProfile,
          token: token
        };
  
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.getUserProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getProfileApiEndpoint + id
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      } 
    } else 
    { this.logoutFunction()
}
  };
  
  handleVerifyOtp = ()=>{
    let {otpValue,changedEmail} = this.state
    const token = localStorage.getItem("authToken");
  
    if (token) {
      const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: token
      };

      const body = {
        "data":{
            "otp": otpValue,
            "email": changedEmail
        }
    }
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addOtpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.enterOtpEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.callTypeApiValidateMobileNo
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
     else 
     {

      this.logoutFunction()
    }
  }

  handleUpdateProfileSuccess = (response: UserProfile) => {
    this.setState({ loading: false })
    if (response) {
      this.handleProfileSuccess(response)
      this.setState({ ApiResponseMessage: configJSON.ProfileUpdate, toastOpen: true })
    }
    else {
      this.setState({ profileApiError: configJSON.ProfileUpdateError })
      setTimeout(()=>{
        this.setState({ profileApiError: '' })
      },5000)
    }
  }

  changeOtpValue = (event:  React.ChangeEvent<HTMLInputElement>)=>{
    const {value} = event.target
    this.setState({otpValue:value})
  }
   handleGenderChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown}>) => {
    const {  value } = event.target;
    let gender = String(value)
    this.setState({inputGender:gender})
  };
  handleCountryChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown}>) =>{
    const {  value } = event.target;
    let country = String(value)
    this.setState({inputCountry:country})
  }
  handleProfileSaveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>)=>{
    if (event.key === 'Enter') {
      const isDisabled =
        !this.state.inputFullName || this.state.inputFullName.toLowerCase().startsWith("add") ||
        !this.state.inputGender || this.state.inputGender.toLowerCase().startsWith("add") ||
        !this.state.inputCountry || this.state.inputCountry.toLowerCase().startsWith("add") ||
        !this.state.inputAddress || this.state.inputAddress.toLowerCase().startsWith("add") ||
        !this.state.inputCity || this.state.inputCity.toLowerCase().startsWith("add") ||
        !this.state.inputContactNum ||
        !this.state.inputDateOfBirth || this.state.inputDateOfBirth.toLowerCase().startsWith("add") ||
        !this.state.inputPinCode || this.state.inputPinCode.toLowerCase().startsWith("add");
      if (!isDisabled) {
        this.handleProfileDetails();
      }
    }
  }
  handleChangePassowrdEnterPress = (event:React.KeyboardEvent<HTMLInputElement> )=>{
    if (event.key === 'Enter') {
      let isDisabled =  !(
        this.state.hasMaxLength &&
        this.state.hasMinLength &&
        this.state.hasNumber &&
        this.state.hasLowerCase &&
        this.state.hasUpperCase
    ) || (this.state.newPassword.length === 0 && this.state.confirmPassword.length === 0)
    if (!isDisabled) {
      this.handleSubmitNewPassword()
    }
  }
}
handleDeleteClose = ()=>{
  this.setState({activeItem:'Profile'})
}
handleAccountDelete = ()=>{
  const userData = localStorage.getItem("userData");
  const token = localStorage.getItem("authToken")
  if(userData && token){
    let parsedData = JSON.parse(userData)
    const userEmail = parsedData.attributes.email
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountAPiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeletAccountEndpoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "data":{
            "email": userEmail
        }
    })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
handleDeleteAccountSuccess =(responseJson:DeleteRes)=>{
  if(responseJson.message && responseJson.message == 'This account deleted successfully'){
    this.setState({deletedAccount: true})
  }
  else this.setState({deletError: responseJson.errors.token})
}

handleProfileSuccess = (response: UserProfile) => {
  const { errors, data } = response;

  if (errors && Array.isArray(errors)) {
    const error = errors[0];

    if (typeof error === 'object' && error.token) {
      this.setState({ profileApiError: error.token });

      if (error.token === 'Token has Expired') {
        this.logoutFunction();
      }
    } else if (error === 'Record not found') {
      this.logoutFunction();
    }
    return;
  }

  if (data && data.attributes) {
    const {
      email,
      full_name,
      gender,
      date_of_birth,
      address1,
      address2,
      city,
      state,
      country,
      zip_code,
      passport_number,
      phone_number,
      passport_expiry_date,
      country_code,image
    } = data.attributes;
    this.setState({
      userEmail: this.getValueOrDefault(email, 'Add Email'),
      fullName: this.getValueOrDefault(full_name, 'Add Full Name'),
      gender: this.getValueOrDefault(gender, 'Add Gender'),
      dateOfBirth: this.getValueOrDefault(date_of_birth, 'Add Date of Birth'),
      address: this.getValueOrDefault(address1, 'Add Address'),
      city: this.getValueOrDefault(city, 'Add City'),
      state: this.getValueOrDefault(state, 'Add State'),
      country: this.getValueOrDefault(country, 'Add Country'),
      pinCocde: this.getValueOrDefault(zip_code, 'Add Pincode'),
      passportNo: this.getValueOrDefault(passport_number, 'Add Passport Number'),
      contactNum: phone_number ? `+${String(phone_number)}` : 'Add Phone Number',
      passportExpiry: this.getValueOrDefault(passport_expiry_date, 'Add Passport Expiry Date'),
      address2: this.getValueOrDefault(address2, 'Add Address'),
      countryCode: this.getValueOrDefault(country_code, ''),
      profilePic : this.getProfilePic(image,baseURL)
    });
  }
};
getProfilePic(image: string | null, baseURL: string) {
  if (!image) return '';
  return image.startsWith(baseURL) ? image : `${baseURL}${image}`;
}

getValueOrDefault = (value: string | null , fallback: string) => (value ? String(value) : fallback);
getTextStyle = (text: string): React.CSSProperties => ({
  color: text.startsWith("Add") ? "#a8a8a8" : "black",
  fontWeight: text.startsWith("Add") ? 500 : "normal",
  textTransform: "capitalize"
});
  handleImageUpload = ()=>{
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
      this.setState({profileApiError:''})
    }
  }
   handleFileChange = (event:  React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const maxSize = 10 * 1024 * 1024
    if (file) {
      const validTypes = ['image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        this.setState({ profileApiError: configJSON.ImageTypeError });
      }
      else if(file.size > maxSize){
        this.setState({profileApiError: configJSON.ImageError})
      }else{
        const reader = new FileReader();
        reader.readAsDataURL(file); 
        reader.onload = () => {
          this.handleProfilePicUpdate(file.name,reader.result as string)
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
      }
    }
  };
  zipcodeFunct = (value:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.zipcodeCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.zipcodeEndPoint+value
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleProfilePicUpdate = (fileName:string,profilePicUrl: string) => {
    const {
      address,
      country,
      address2,
      contactNum,
      passportExpiry,
      passportNo,
      state,
      city,
      dateOfBirth,
      fullName,
      gender,
      pinCocde,
      countryCode
    } = this.state;
  
    this.setState({ loading: true });
    const checkEditValue = (value: string) => {
      return  value.startsWith("Edit") ? null : value;
    };
    const body = {
      data: {
        type: "email_account",
        attributes: {
          country: checkEditValue(country),
          phone_number: checkEditValue(contactNum),
          city: checkEditValue(city),
          state: checkEditValue(state),
          country_code: checkEditValue(countryCode),
          gender: checkEditValue(gender),
          zip_code: checkEditValue(pinCocde),
          passport_number: checkEditValue(passportNo),
          passport_expiry_date: checkEditValue(passportExpiry),
          address1: checkEditValue(address),
          address2: checkEditValue(address2),
          full_name: checkEditValue(fullName),
          date_of_birth: checkEditValue(dateOfBirth)
        },
        avatar:
        {
          "file_name": fileName,
          "content_type": "avatar/jpg",
          image: checkEditValue(profilePicUrl)
        }
      }
    };
  
    const token = localStorage.getItem("authToken");
    if (token) {
      const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editImageApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editProfileEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.callTypeApiValidateMobileNo
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.logoutFunction()
    }
  
    this.setState({ editProfileModal: false, loading: false });
  };
  
  handleImageSuccess = (responseJson: UserProfile) => {
    const { errors } = responseJson;
    if (errors && Array.isArray(errors)) {
      const error = errors[0];
      if (typeof error === 'object' && error.token) {
        this.setState({ profileApiError: error.token });
        if (error.token === 'Token has Expired') {
          this.logoutFunction();
        }
      } else if (error === 'Record not found') {
        this.logoutFunction();
      }
    }else if (responseJson.data.attributes.image) {
      this.setState({ profilePic: responseJson.data.attributes.image });
    }
  };
  
  handleProfileDetails = ()=>{
    const {inputAddress,inputCountry,inputAddress2,countryCode,profilePic,inputContactNum,inputPassportExpiry,inputPassportNo,inputState,inputCity,inputDateOfBirth,inputFullName,inputGender,inputPinCode} = this.state
    this.setState({loading:true})
    const body = {
      "data": {
        "type": "email_account",
        "attributes": {
          "country":inputCountry,
          "phone_number": inputContactNum,
          "city": inputCity,
          "state": inputState,
          "country_code": countryCode,
          "gender": inputGender,
          "zip_code": inputPinCode,
          "passport_number": inputPassportNo,
          "passport_expiry_date": inputPassportExpiry,
          "address1": inputAddress,
          "address2": inputAddress2,
          "full_name": inputFullName,
          "date_of_birth": inputDateOfBirth
        }
      }
    }
    const token = localStorage.getItem("authToken");
    if (token) {
      const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: token
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editUserProfileApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editProfileEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.callTypeApiValidateMobileNo
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else this.logoutFunction()
    this.setState({editProfileModal:false,})
  }

  
  handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>)=>{
    let {value}=  event.target
    this.setState({inputCity: value})
  }
  handleInputState = (event: React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({inputState: event.target.value})
  }
  setMobileNo(text:string,country:any) {
    let phone = text.replace(country,'')
    this.setState({
        inputContactNum: phone,
        countryCode: country.dialCode
        
    });
}
handlePassportNumber = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({inputPassportNo:event.target.value})
}
zipcodeResponse = (responseJson: ZipType) => {
    if (!responseJson.error && responseJson) {
      this.setState({ inputCountry: responseJson.country, inputCity: responseJson.city, inputState: responseJson.state })
    }
  }
  handleChangePincode = (event: React.ChangeEvent<HTMLInputElement>)=>{
    let {value}=  event.target
    this.setState({inputPinCode: value})
  }
  handlePasswordSuccessRes = (responseJson:PasswordResponse)=>{
    this.setState({loading:false})
    if (responseJson.errors && responseJson.errors.length > 0) {
      this.setState({passwordError:responseJson.errors[0].token });
    } else if (responseJson.message) {
      if(responseJson.message.toLowerCase().includes("incorrect"))
        this.setState({passwordError:responseJson.message})
      else {
        this.setState({oldPassword:'',passwordError:'',newPassword:'',confirmPassword:'', VerifyOtpModal: false,ApiResponseMessage: responseJson.message, toastOpen: true });
        this.handleGetProfileDetails()
      }
    }
    else{
      this.setState({passwordError: configJSON.Unexpected_error})
    }
  }
  logoutFunction=()=>{
    localStorage.removeItem("authToken")
    localStorage.removeItem("userData")
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }
  handleResendOtp = ()=>{
    this.setState({successOtpText:"New Otp has been sent to your email"})
    this.setTimerDefault()
    this.handleverify()
  }
  checkForCapitalLetter = (str: string) => {
    return /[A-Z]/.test(str);
  };

  checkLowerCaseLetter = (text: string) => {
    return /[a-z]/.test(text);
  };

  checkForNumber = (num: string) => {
    return /\d/.test(num);
  };

  checkMinLength = (text: string) => {
    const minLength = 8;
    return text.length >= minLength;
  };
  checkForSpecialChar = (str: string) => {
    return /[!@#$%^&*(),.?":{}|<>]/.test(str);
  };

  countrylistFunct = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountry
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

}
