// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Cfgdsavinodeintegration from "../../blocks/cfgdsavinodeintegration/src/Cfgdsavinodeintegration";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cfapiintegrationforhimalayanbankapi from "../../blocks/cfapiintegrationforhimalayanbankapi/src/Cfapiintegrationforhimalayanbankapi";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import RoundTrip from '../../blocks/catalogue/src/RoundTrip.web'
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ChangePassword from "../../blocks/forgot-password/src/ChangePassword.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfitinerarymanagement from "../../blocks/cfitinerarymanagement/src/Cfitinerarymanagement.web";
import PackageBooking from "../../blocks/ordermanagement/src/PackageBooking.web"
import PackageDetail from "../../blocks/cfitinerarymanagement/src/PackageDetail.web";
import AllPackage from "../../blocks/productdescription/src/AllPackage.web";
import ViewAllPackages from "../../blocks/productdescription/src/ViewAllSelling.web";
import ViewAllInternationalPackages from "../../blocks/productdescription/src/ViewAllNational.web"
import ViewAllOfferPackage from "../../blocks/productdescription/src/ViewAllOfferPackage.web"
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfmanipulatetheticketamounts from "../../blocks/cfmanipulatetheticketamounts/src/Cfmanipulatetheticketamounts";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Cfcreatepopulardestinations from "../../blocks/cfcreatepopulardestinations/src/Cfcreatepopulardestinations";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfmanagetaxservicechargesandvat from "../../blocks/cfmanagetaxservicechargesandvat/src/Cfmanagetaxservicechargesandvat";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailAccountRegistrationBlock from "../../blocks/email-account-registration/src/EmailAccountRegistration.web"
import EmailAccountDetail from "../../blocks/email-account-registration/src/EmailAccountDetail.web"
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Posintegrationbillinginvoicingsystem2 from "../../blocks/posintegrationbillinginvoicingsystem2/src/Posintegrationbillinginvoicingsystem2";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Trending from "../../blocks/trending/src/Trending";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import PricingEngine from "../../blocks/pricingengine/src/PricingEngine";
import RefundManagement from "../../blocks/refundmanagement/src/RefundManagement.web"
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import SingleBlog from "../../blocks/blogpostsmanagement/src/BlogpostsManagement.web";
import SingleOffers from "../../blocks/promocodes/src/OffersCode.web";
import OrderManagementOneWay from "../../blocks/ordermanagement/src/OrderManagementOneWay.web"
import OrderManagementRoundTrip from "../../blocks/ordermanagement/src/OrderManagementRoundTrip.web"
import MultiCitySearching from "../../blocks/catalogue/src/MultiCitySearching.web";
import PaymentSuccessfull from "../../blocks/cfapiintegrationforhimalayanbankapi/src/PaymentSuccessfull.web";
import PaymentFailed from "../../blocks/cfapiintegrationforhimalayanbankapi/src/PaymentFailure.web";
import Cfseatselection1 from '../../blocks/cfseatselection1/src/Cfseatselection1.web';
import ViewallOffers from '../../blocks/promocodes/src/ViewallOffers.web'
import ViewallBlogs from '../../blocks/promocodes/src/ViewallBlogs.web'
import ViewallPopularDestination from '../../blocks/landingpage/src/ViewallPopularDestination.web'
import userprofile from "../../blocks/user-profile-basic/src/UserProfileBasic.web";
import MyTrips from '../../blocks/ordermanagement/src/MyTrips.web'

const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Cfgdsavinodeintegration:{
 component:Cfgdsavinodeintegration,
path:"/Cfgdsavinodeintegration"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
ViewallOffers:{
  component: ViewallOffers,
  path:"/alloffers"
},
ViewallBlogs:{
  component: ViewallBlogs,
  path:"/allblogs"
},
ViewallPopularDestination: {
  component: ViewallPopularDestination,
  path:"/allpopulardestinations"
},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Cfapiintegrationforhimalayanbankapi:{
 component:Cfapiintegrationforhimalayanbankapi,
path:"/Cfapiintegrationforhimalayanbankapi"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ChangePassword:{
  component:ChangePassword,
path:"/ChangePassword"
},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
PackageDetail:{
  component:PackageDetail,
  path:"/PackageDetail/:id"
},
AllPackage:{
  component:AllPackage,
  path:"/AllPackage/:obj"
},

Cfitinerarymanagement:{
 component:Cfitinerarymanagement,
 path:"/packages"},
 PackageBooking:{
  component:PackageBooking,
  path:"/PackageBooking"},
  ViewAllPackages:{
    component:ViewAllPackages,
    path:"/ViewAllSellingPackages"
  },
  ViewAllInternationalPackages:{
    component:ViewAllInternationalPackages,
    path:"/ViewAllInternationalPackages"
  },
  ViewAllOfferPackage:{
    component:ViewAllOfferPackage,
    path:"/ViewAllOfferPackage"
  },
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cfmanipulatetheticketamounts:{
 component:Cfmanipulatetheticketamounts,
path:"/Cfmanipulatetheticketamounts"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
RoundTrip:{
  component:RoundTrip,
 path:"/RoundTrip"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
// UserProfileBasicBlock:{
//  component:UserProfileBasicBlock,
// path:"/UserProfileBasicBlock"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
MyTrips: {
  component:MyTrips,
  path:"/mytrips"
},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
SingleBlog:{
  component:SingleBlog,
 path:"/Blog/:id"},
 SingleOffers:{
component:SingleOffers,
path:"/Offers/:id"
 },
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Cfcreatepopulardestinations:{
 component:Cfcreatepopulardestinations,
path:"/Cfcreatepopulardestinations"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Cfmanagetaxservicechargesandvat:{
 component:Cfmanagetaxservicechargesandvat,
path:"/Cfmanagetaxservicechargesandvat"},
Cfseatselection1 :{
  component:Cfseatselection1,
  path:"/seatselection"
},
RefundManagement: {
  component: RefundManagement,
  path:"/cancel-booking/:id"
},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
EmailAccountRegistrationBlock:{
  component:EmailAccountRegistrationBlock,
 path:"/signup"},
 EmailAccountDetail:{
  component:EmailAccountDetail,
 path:"/EmailAccountDetail"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Posintegrationbillinginvoicingsystem2:{
 component:Posintegrationbillinginvoicingsystem2,
path:"/Posintegrationbillinginvoicingsystem2"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Trending:{
 component:Trending,
path:"/Trending"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
PricingEngine:{
 component:PricingEngine,
path:"/PricingEngine"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OrderManagementOneWay:{
  component:OrderManagementOneWay,
 path:"/OrderManagementOneWay",
 protected: true},
 OrderManagementRoundTrip:{
  component:OrderManagementRoundTrip,
  path:"/OrderManagementRoundTrip",
  protected: true
 },
 MultiCitySearching:{
  component:MultiCitySearching,
 path:"/MultiCitySearching"},
 PaymentSuccessfull:{
  component:PaymentSuccessfull,
 path:"/PaymentSuccessfull"},
 PaymentSuccessfull:{
  component:userprofile,
 path:"/userprofile"},
PaymentFailed:{
 component:PaymentFailed,
 path:"/PaymentFailed"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw', fontFamily:"Inter, sans-serif" }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;