import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from 'moment';
import { createRef, RefObject } from "react";
import { cheapestIcon, discountIcon, nonStopIcon, starIcon } from "./assets";
interface AirportSearch {
  id:string;
  Code: string;
  Name: string;
  CityCode: string;
  CountryCode: string;
}

interface AirportResponse {
  airport_list: AirportSearch[] | null;
}
interface FlightDataAir{
  id: number;
  AirlineName: string;
  Count:number;
  checked: boolean,
}
interface PopularFilterProps {
  checked: boolean;
  name: string;
}
export interface FlightData {
  id:number;
  flight_number: string;
  origin: string;
  destination: string;
  connection: number;
  departure_time: string;
  arrival_time: string;
  endArrival_time:string;
  per_adult_price: string;
  duration: string;
  AirlineName:string;
  
}
interface URLSearchParamsType {
  departure_date: Date | string;
  origin: string;
  originFlight: string;
  destination: string;
  destinationFlight:string;
  cabin_class: string;
  adult: string;
  children: string;
  infant: string;
  trip_type: string;
}
export interface Stop {
  0: number;
  1: string;
}

export interface SegmentKey {
  Key: string;
}

export interface AirSegmentRef {
  AirlineName: string;
  Key: string;
  FlightNumber: string;
  Origin: string;
  Destination: string;
  DepartureTime: string;
  ArrivalTime: string;
  FlightTime: string;
  Distance: string;
  ETicketability: string;
  Equipment: string;
  FlightDetailsRef: {
    Key: string;
  };
}

export interface BookingInfo {
  BookingCode: string;
  BookingCount: string;
  CabinClass: string;
  FareInfoRef: string;
  SegmentRef: string;
}

export interface TaxInfo {
  Category: string;
  Amount: string;
  Key: string;
}

export interface BaggageAllowance {
  MaxWeight: {
    Value: string;
    Unit: string;
  };
}

export interface FareSurcharge {
  Key: string;
  Type: string;
  Amount: string;
}

export interface FareInfo {
  Key: string;
  FareBasis: string;
  PassengerTypeCode: string;
  Origin: string;
  Destination: string;
  EffectiveDate: string;
  DepartureDate: string;
  Amount: string;
  NegotiatedFare: string;
  NotValidBefore: string;
  NotValidAfter: string;
  FareSurcharge: FareSurcharge;
  BaggageAllowance: BaggageAllowance;
  FareRuleKey: string;
  Brand: {
    Key: string;
    BrandID: string;
    UpSellBrandID: string;
    BrandTier: string;
    UpsellBrand: {
      FareBasis: string;
      FareInfoRef: string;
    };
  };
}

export interface AirPricingInfo {
  Key: string;
  TotalPrice: string;
  BasePrice: string;
  ApproximateTotalPrice: string;
  ApproximateBasePrice: string;
  Taxes: string;
  ApproximateTaxes: string;
  LatestTicketingTime: string;
  PricingMethod: string;
  Refundable: string;
  ETicketability: string;
  PlatingCarrier: string;
  ProviderCode: string;
  Cat35Indicator: string;
  FareInfoRef: {
    Key: string;
  }[];
  BookingInfo: BookingInfo[];
  TaxInfo: TaxInfo[];
  FareCalc: string;
  PassengerType: {
    Code: string;
  };
  ChangePenalty: {
    PenaltyApplies: string;
    Amount: string;
  };
  CancelPenalty: {
    PenaltyApplies: string;
    Amount: string;
  };
  FareInfoList: FareInfo[];
  Brand: {
    Key: string;
    BrandID: string;
    Name: string;
    BrandedDetailsAvailable: string;
    Carrier: string;
    Title: string[];
    Text: string[];
    ImageLocation: string[];
  }[];
}

export interface AirPricingSolution {
  air_pricing_key: string;
  total_price: string;
  base_price: string;
  taxes: string;
  connection: number;
  travel_time: string;
  currency_type: string;
  adult_per_price: string;
  segment_ref_key: SegmentKey[];
  flight_info: AirSegmentRef[];
  air_pricing_info: AirPricingInfo;
}
export interface ViewPrice{
  AirItenanry: string[];
  adult_per_price: string; currency_type: string; BrandName: string; BaggageInfo: string[]; }
export interface Flight {
  view_prices: ViewPrice[];
  FlightNumber:string;
  air_segment_keys: SegmentKey[];
  adult_per_price: string;
  connection: number;
  flight_details: AirPricingSolution[];
}

export interface PriceRange {
  min: number;
  max: number;
}

export interface AirData {
  stops: Stop[];
  price_range: PriceRange;
  flight_list: Flight[];
  error: ErrorSec;
  errors: string;
}
export interface ErrorSec {
  Description: string
}

interface SearchText {
  id: string,
  state: string,
  country: string,
  flight: string,
  stateCode: string
}
type DateRange = {
  selection: {
    startDate: Date;
    endDate: Date;
  };
};

interface PopularCityProp {
  id: string
  city: string,
  airportName: string,
  airportCode: string
  country: string
}
interface OptionType {
  id: string,
  price: string,
  optionType: string,
  cabinType: {
    cabinWeight: number,
    cabinBaggageAllow: boolean,
  }
  checkinType: {
    checkinWeight: number,
    checkinbaggageAllow: boolean,
  }
  cancellationType: {
    cancellation: boolean,
    cancellationMsg: string
  }
  dateChangeType: {
    dateChange: boolean,
    dateChangeMsg: string
  }
  seatType: {
    seat: string,
    aboutSeatType: boolean
  }
  mealType: {
    meal: string,
    aboutMealType: boolean
  }
}

interface TimePrice {
  time: string;
  price: string;
}

interface Airline {
  checked: boolean;
  name: string;
  price: string;
  img: string;
}

export interface ListCityDummyType {
  "id": string, "country": string, "state": string, "flight": string, "stateCode": string
}

interface OfferButtonProps {
  src: string;
  heading: string;
  content: string;
}

interface DatePrice {
  dateItem: string;
  isHighlighted: boolean;
}
interface TripType {
  tripType: string
  tripSelected: boolean
}
interface FilterFlightDetails {
  connection: number;
  via: string;
  route: string;
  layour_info: {
      change_flight: boolean;
      origin: string;
      destination: string;
      departure_time: string;
      arrival_time: string;
      layover_time: string;
      duration: string;
      FlightNumber: string;
      AirlineName:string;
     
  }[];
}
interface LayourInfoDe{
  change_flight: boolean;
      origin: string;
      departure_time: string;
      arrival_time: string;
      layover_time: string;
      destination: string;
   
      duration: string;
      FlightNumber: string;
}
interface PriceDataType {
  adult_per_price: string;
  currency_type: string;
  BrandName: string;
  BaggageInfo: string[];
  AirItenanry:any;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  arrivalTimePrice:TimePrice | null;
  departureTimePrice:TimePrice | null;
  priceListCard:PriceDataType[];
  filterFlightDetails:FilterFlightDetails | null;
  flightAllDataJson:Flight[];
  visibleDetailIndex:null | number
  loading: boolean;
  cabin_class: string;
  infant: number;
  inputCityTo:string;
  inputCityFrom:string;
  trip_type: string;
  searchTextFrom: string;
  searchToText: string;
  listCityDummy: ListCityDummyType[]
  arrayHolder: string[];
  token: string;
  priceModal: boolean
  selectedFlightPrice: FlightData | null
  tripType: string;
  priceIndex: number | null;
  authToken: string | null;
  isOpen: boolean;
  startDate: Date | null;
  endDate: Date | null;
  endDate1: Date | null;
  activePicker: string;
  selectedOption: string;
  travelClassSelected: boolean
  searchText: {
    state: string,
    country: string,
    flight: string,
    stateCode: string
  };
  toText: {
    state: string,
    country: string,
    flight: string,
    stateCode: string
  }
  filteredItems: SearchText[];
  filteredItemsTo: SearchText[];
  anchorEl: Element | null;
  anchorElTo: Element | null;
  anchorElDeparture: Element | null;
  setAnchorAi: Element | null;
  searchTextClass: {
    classNumber: string;
    title: string;
    travelClass: string;
  }
  anchorElClass: Element | null;
  countAdult: number;
  countChild: number;
  travelStatus: string;
  fullTravelObj: {
    countAdult: number,
    countChild: number,
    travelStatus: string,
    countInfant: number
  }
  error: string;
  popularFilters: PopularFilterProps[]
  timePriceArray: TimePrice[]
  airlinesList: Airline[]
  dateCustomPrices: DatePrice[]
  offersList: OfferButtonProps[]
  filtersDrawer: boolean
  filterCheckBox: boolean
  windowWidth: number
  tripOpen: boolean
  tripOptions: TripType[]
  fromModalOpen: boolean
  popularCities: PopularCityProp[]
  toModalOpen: boolean
  currentIndex: number;
  searchField: string;
  anchorElTripType: Element | null
  selectedTripType: string
  errorResSearch: string;
  flightAllDataArray: FlightData[];
  flightAllDataDummy: FlightData[];
  enableSearch: boolean;
  price_range: {
    "min": number,
    "max": number
  }
  selectedPrice: number,
  minPrice: number,
  maxPrice: number,
  showError:boolean
  fareOptionSelected: number | null
  fareOption: ViewPrice | null
  countInfant: number;
  selectedTripOption: string
  shownPopularFilters: PopularFilterProps[]
  selectedPopularFilter:PopularFilterProps[]
  airLinesData:FlightDataAir[]
  selectedAirlines:FlightDataAir[]
  errorPriceInfo:any
  inputApiSearchFrom: string
  inputApiSearchTo: string
  AirItenanryData:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId = "";
  airportSearchApiCallId ="";
  airportSearchToApiCallId="";
  datePricesRef: RefObject<Element> = createRef();
  calendarBoxRef = createRef<HTMLDivElement>();
  oneWaySearchApiCallId: string = "";
  priceApiCallId="";
  roundTripApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area Start
      AirItenanryData:[],
      errorPriceInfo:"",
      inputApiSearchFrom:"",
      inputApiSearchTo:"",
      selectedAirlines: [],
      setAnchorAi:null,
      airLinesData:[],
      selectedPopularFilter:[],
      departureTimePrice:null,
      arrivalTimePrice:null,
      timePriceArray: [
        { time: "Before 6AM", price: "$4200" },
        { time: "6AM to 12PM", price: "$5200" },
        { time: "12PM to 6PM", price: "$6200" },
        { time: "After 6PM", price: "$6500" }
      ],
      shownPopularFilters: [
        { checked: false, name: "Non-stop" },
        { checked: false, name: "1 Stop"},
        { checked: false, name: "2 Stop"},
        { checked: false, name: "Morning Departures"},
        { checked: false, name: "Late Departures"},
      ], 
      priceListCard:[],
      filterFlightDetails:null,
      flightAllDataJson:[],
      visibleDetailIndex: null,
      selectedPrice: 50,
      minPrice: 0,
      maxPrice: 100,
      price_range: {
        min: 0,
        max: 0
      },
      loading: false,
      priceIndex: null,
      selectedTripOption: "",
      flightAllDataArray: [],
      flightAllDataDummy: [],
      errorResSearch: '',
      showError:false,anchorElDeparture: null,
      cabin_class: "",
      inputCityTo:"",
      inputCityFrom:"",
      infant: 0,
      countInfant: 0,
      trip_type: "",
      searchTextFrom: "",
      searchToText: "",
      arrayHolder: [],
      token: "",
      fareOption: null,
      fareOptionSelected:null,
      travelClassSelected: false,
      currentIndex: 0,
      priceModal: false,
      selectedFlightPrice: null,
      searchField: "",
      selectedTripType: "",
      filteredItems: [],
      anchorElTripType: null,
      filtersDrawer: false,
      fromModalOpen: false,
      enableSearch: false,
      searchText: {
        state: "",
        country: "",
        flight: "",
        stateCode: ""
      },
      toText: {
        state: "",
        country: "",
        flight: "",
        stateCode: ""
      },
      tripOpen: false,
      tripType: "",
      anchorEl: null,
      anchorElTo: null,
      filteredItemsTo: [],
      selectedOption: "One Way",
      activePicker: "",
      authToken: "",
      isOpen: false,
      startDate: null,
      endDate: null,
      endDate1: null,
      toModalOpen: false,
      searchTextClass: {
        classNumber: "",
        title: "",
        travelClass: ""
      },
      popularCities: [
        { id: '1', country: 'India', city: 'New Delhi', airportName: 'Indira Gandhi International Airport', airportCode: 'DEL' },
        { id: '1', country: 'India', city: 'Mumbai', airportName: 'Shivaji International Airport', airportCode: 'BOM' },
        { id: '1', country: 'India', city: 'Mumbai', airportName: 'Shivaji International Airport', airportCode: 'BOM' },
        { id: '1', country: 'India', city: 'Bangkok', airportName: 'Indira Gandhi International Airport', airportCode: 'BKK' },
        { id: '1', country: 'India', city: 'New Delhi', airportName: 'Indira Gandhi International Airport', airportCode: 'DEL' },
        { id: '1', country: 'India', city: 'New Delhi', airportName: 'Indira Gandhi International Airport', airportCode: 'DEL' },
      ],
      listCityDummy: [{ "id": "1", "country": "india", "state": "New Delhi", "flight": " Indira Gandhi International Airport", "stateCode": "DEL" }
        , { "id": "2", "country": "india", "state": "Mumbai", "flight": "Shivaji International Airport", "stateCode": "BOM" },
      { "id": "3", "country": "Bangkok", "state": "Bangkok", "flight": " Indira Gandhi International Airport", "stateCode": "BKK" },
      { "id": "4", "country": "india", "state": "New Delhi", "flight": " Indira Gandhi International Airport", "stateCode": "DEL" }
        , { "id": "2", "country": "india", "state": "Mumbai", "flight": "Shivaji International Airport", "stateCode": "BOM" },
      { "id": "3", "country": "Bangkok", "state": "Bangkok", "flight": " Indira Gandhi International Airport", "stateCode": "BKK" }],
      anchorElClass: null,
      countAdult: 1,
      countChild: 0,
      travelStatus: "ECONOMY",
      fullTravelObj: {
        countChild: 0,
        travelStatus: "ECONOMY",
        countAdult: 1,
        countInfant: 0,
      },
      error: "",
      filterCheckBox: false,
      tripOptions: [{ tripType: 'One Way', tripSelected: false }, { tripType: 'Round Trip', tripSelected: false }, { tripType: 'Multicity', tripSelected: false }],
      popularFilters: [
        { checked: false, name: 'Non-stop' },
        { checked: false, name: 'Morning Departures'},
        { checked: false, name: 'Late Departures' }
      ],
      windowWidth: 1440,
      airlinesList: [
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' },
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' },
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' },
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' }
      ],
      dateCustomPrices: [],
      offersList: [
        { src: cheapestIcon, heading: 'Cheapest', content: '$5232 | 02h 05min' },
        { src: nonStopIcon, heading: 'NON STOP FIRST', content: '$5232 | 02h 05min' },
        { src: starIcon, heading: 'YOU MAY PREFER', content: '$5232 | 02h 05min' },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getToken();

    const urlSearch = new URLSearchParams(window.location.search);
    const urlParams: URLSearchParamsType = {
      departure_date: urlSearch.get('departure_date') || '',
      origin: urlSearch.get('origin') || '',
      originFlight: urlSearch.get('originFlight') || '',
      destination: urlSearch.get('destination') || '',
      destinationFlight: urlSearch.get('destinationFlight') || '',
      cabin_class: urlSearch.get('cabin_class') || '',
      adult: urlSearch.get('adult') || '',
      children: urlSearch.get('children') || '',
      infant: urlSearch.get('infant') || '',
      trip_type: urlSearch.get('trip_type') || ''
    };
    this.customFunR(urlParams)
    // Customizable Area End
  }
  
  // Customizable Area Start
  customFunR=(urlParams:URLSearchParamsType)=>{
    if (urlParams.trip_type === "one_way" || urlParams.trip_type === "One Way") {
      this.setState({ selectedTripType: "One Way" })
    }
    if (urlParams.departure_date) {
      const departureDate = new Date(urlParams.departure_date);
      this.setState({ startDate: departureDate })
      this.setState({endDate:departureDate})
    }
    if (urlParams.adult || urlParams.children || urlParams.infant || urlParams.cabin_class) {
      let obj = {
        countAdult: Number(urlParams.adult),
        countChild: Number(urlParams.children),
        travelStatus: String(urlParams.cabin_class),
        countInfant: Number(urlParams.infant)
      }
      this.setState({ fullTravelObj: obj, travelClassSelected: true ,countInfant: Number(urlParams.infant),  countAdult: Number(urlParams.adult), travelStatus: String(urlParams.cabin_class),
        countChild: Number(urlParams.children),})
    }
    if (urlParams.origin) {
      let origin_obj = {
        state: urlParams.origin,
        country: "",
        flight: urlParams.originFlight,
        stateCode: urlParams.origin
      }
      this.setState({ searchText: origin_obj,inputCityFrom: urlParams.origin })
    }
    if (urlParams.destination) {
      let dest_obj = {
        state: urlParams.destination,
        country: "",
        flight: urlParams.destinationFlight,
        stateCode: urlParams.destination
      }
      this.setState({ toText: dest_obj,inputCityTo: urlParams.destination })
    }
    this.setState({ selectedTripOption: urlParams.trip_type })
    if (urlParams) {
      this.urlParamFunct(urlParams);
    }
  }
  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
  // Customizable Area End

  // Customizable Area Start
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.oneWaySearchApiCallId && this.oneWaySearchApiCallId!=null:
            this.handleGetOneWayRes(responseJson)
            break;
          case apiRequestCallId === this.airportSearchApiCallId && this.airportSearchApiCallId!=null:
            this.handleAirportSearchRes(responseJson)
            break;
          case apiRequestCallId === this.airportSearchToApiCallId && this.airportSearchToApiCallId!=null:
            this.handleAirportSearchToRes(responseJson)
          break
          case apiRequestCallId === this.priceApiCallId && this.priceApiCallId!=null:
            this.handlePriceResponse(responseJson)
            break
            case apiRequestCallId === this.roundTripApiCallId && this.roundTripApiCallId!=null:
            this.roundTripResponse(responseJson)
            break
         default:
        }
      }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  handleFiltersToggle = () => {
    this.setState({ filtersDrawer: !this.state.filtersDrawer })
  }
  scrollToHighlightedDate = (highlightedIndex:number) => {
    
      const element = document.getElementById(`date-item-${highlightedIndex}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }
 
  handleNextClick = () => {
    if (this.datePricesRef.current) {
      this.datePricesRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }

  handlePrevClick = () => {
    if (this.datePricesRef.current) {
      this.datePricesRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }
  handleDateSelect = (datePrice: DatePrice) => {
    const updatedDatePrices = this.state.dateCustomPrices.map((price_val) => {
      if (price_val.dateItem === datePrice.dateItem) {
        return { ...price_val, isHighlighted: true };
      } else {
        return { ...price_val, isHighlighted: false };
      }
    });

    this.setState({ dateCustomPrices: updatedDatePrices });
    this.OneWaySearchWithDate(datePrice)
  }
  handleTripClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElTripType: event.currentTarget });
    this.setState({ tripOpen: !this.state.tripOpen })
  };

  handleSelectTriptype = (selectedIndex: number) => {
    const updatedTripOptions = this.state.tripOptions.map((option, index) => {
      if (index === selectedIndex) {

        return { ...option, tripSelected: !option.tripSelected };
      }
      return { ...option, tripSelected: false };
    });
    this.setState({ selectedTripType: updatedTripOptions[selectedIndex].tripType })
    this.setState({ tripOptions: updatedTripOptions,endDate:null, endDate1:null, startDate:null });
this.handleClose()
    this.handleCloseTripOptions()
  };
  handleCloseTripOptions = () => {
    this.setState({ tripOpen: false })
  }

  reverseFunct = () => {
    this.setState({toText:this.state.searchText, searchText:this.state.toText})
  }

  handleClickClass = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElClass: event.currentTarget });
  };
  handleCloseClass = () => {
    this.setState({ anchorElClass: null });
  };
  increaseCount = () => {
    const {countChild,countInfant,countAdult} = this.state
    this.setState(prevState => {
      const total = countAdult + countChild + countInfant;
      if (total < 9) {
        return {
          countAdult: prevState.countAdult + 1,
          error: ""
        };
      } else {
        this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
        return null;
      }
    });
  };

  decreaseCount = () => {
    this.setState(prevState => ({
      countAdult: prevState.countAdult === 1 ? prevState.countAdult : prevState.countAdult - 1,
      error: ""
    }));
  };

  increaseCountChild = () => {
    const {countChild,countInfant,countAdult} = this.state
    this.setState(prevState => {
      const total = countAdult + countChild + countInfant;
      if (total < 9) {
        return {
          countChild: prevState.countChild + 1,
          error: ""
        };
      } else {
        this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
        return null;
      }
    });
  };

  decreaseCountChild = () => {
    this.setState(prevState => ({
      countChild: prevState.countChild === 0 ? prevState.countChild : prevState.countChild - 1,
      error: ""
    }));
  };

  errorFunct = (errorMsg: string) => {
    this.setState({
      error: errorMsg
    });
  };
  handleClickStatus = (buttonName: string) => {
    this.setState({
      travelStatus: buttonName,
      cabin_class: buttonName,
    });
  };
  handleCancelTravel = () => {
    this.setState({
      travelStatus: "Economy", countAdult: 1, countChild: 0,countInfant:0,infant:0,cabin_class: "ECONOMY",
      fullTravelObj: {
        countAdult: 1,
        countChild: 0,
        countInfant: 0,
        travelStatus: "ECONOMY",
      },
      travelClassSelected: false,
      anchorElClass: null,
      error: ""
    })
  }
  handleDone = () => {
    this.setState({
      fullTravelObj: {
        countAdult: this.state.countAdult,
        countChild: this.state.countChild,
        countInfant: this.state.countInfant,
        travelStatus: this.state.travelStatus
      },
      travelClassSelected: true,
      anchorElClass: null
    })

  }
  
  handleClose = () => {
    this.setState({ anchorEl: null, anchorElTo: null, currentIndex: 0, searchField: "", filteredItems: [] });
  };

  togglePicker = (picker: string,event: React.MouseEvent<HTMLElement>) => {

    this.setState({
      isOpen: true,
      activePicker: picker,
      anchorElDeparture: event.currentTarget,
      setAnchorAi: event.currentTarget
    });
    if (picker === "endDate") {
      this.setState({ selectedOption: "Roundtrip" })
    }

  };
  formatDate = (date: Date) => {
    if (date) {

      const dayaa = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear().toString().slice(-2);
      const weekday = date.toLocaleString('default', { weekday: 'long' });
      return `${dayaa} ${month}'${year} ${weekday}`;
    }
  };
  formatDatePart = (value: Date, part: string) => {
    let dateValue = this.formatDate(value)
    if (dateValue) {
      const parts = dateValue.split(' ');
      switch (part) {
        case "day":
          return parts[0];
        case "month":
          return parts[1];
        case "year":
          return parts[2];
      }
    }
  };
  handleDateChange = (ranges: DateRange) => {
    const { startDate, endDate } = ranges.selection;

    this.setState({
      startDate,
      endDate,
      
      endDate1:endDate
    });
  };
  handleApply = () => {
    this.setState({ isOpen: false, activePicker: "", });
  };

  handleCancel = () => {
    this.setState({ startDate: null, activePicker: "", endDate: null, endDate1: null, isOpen: false, });
  };
  handleShowPriceModal = (selectedFlight: FlightData,index:number) => {
    const inputData = this.state.flightAllDataJson[selectedFlight.id].view_prices
    const priceData = inputData.map((item) => {
      return {
        adult_per_price: item.adult_per_price,
          currency_type: item.currency_type,
          BrandName:item.BrandName,
          BaggageInfo:item.BaggageInfo,
          AirItenanry:item.AirItenanry,
      }
  })
    this.setState({ priceModal: true,errorPriceInfo:'' })
    this.setState({ selectedFlightPrice: selectedFlight, priceListCard:priceData })
  }
  handleClosePriceModal = () => {
    this.setState({ priceModal: false,loading:false,priceIndex:null })
    this.setState({ selectedFlightPrice: null, fareOption: null, fareOptionSelected:null})
  }
  handleFareOptionSelect = (item: ViewPrice, index:number) => {
    this.setState({ fareOptionSelected:index, fareOption: item })
  }
  urlParamFunct = (urlSearch: URLSearchParamsType) => {
    this.setState({
      searchTextFrom: urlSearch.origin,
      searchToText: urlSearch.destination,
      countAdult: parseInt(urlSearch.adult),
      countChild: parseInt(urlSearch.children),
      cabin_class: urlSearch.cabin_class,
      infant: parseInt(urlSearch.infant),
      trip_type: urlSearch.trip_type
    })
    this.OneWaySearch(urlSearch)
    this.DateFilterArray(urlSearch.departure_date)
  }
   handleSelectedPopularFilter=()=>{
        this.setState({ shownPopularFilters: [
          { checked: false, name: "Non-stop" },
          { checked: false, name: "1 Stop"},
          { checked: false, name: "2 Stop"},
          { checked: false, name: "Morning Departures"},
          { checked: false, name: "Late Departures"},
        ]});
      }
  OneWaySearch = (urlSearch: URLSearchParamsType) => {
    this.setState({ loading: true });
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.oneWaySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneWayEndPoint + `departure_date=${urlSearch.departure_date}&origin=${urlSearch.origin}&destination=${urlSearch.destination}&cabin_class=${urlSearch.cabin_class}&adult=${urlSearch.adult}&children=${urlSearch.children}&infant=${urlSearch.infant}&trip_type=${urlSearch.trip_type}`
    );

    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSearch = () => {
    this.setState({visibleDetailIndex:null})
    if(this.state.selectedTripType==="One Way"){
      if (this.validateFields()) 
          this.SearchDataOneWay()
    } else if(this.state.selectedTripType == "Round Trip"){
      if((this.roundTripValidate()) ){
        this.roundTripApi()
      }
    }
  }

  roundTripValidate = () => {
    const { searchText, toText, startDate, fullTravelObj, endDate1 } = this.state;
    if (!searchText.stateCode) {
        this.setState({ errorResSearch: 'Origin city is required.' });
        return false;
    }
    if (!toText.stateCode) {
        this.setState({ errorResSearch: 'Destination city is required.' });
        return false;
    }
    if (!startDate) {
        this.setState({ errorResSearch: 'Departure Date is required.' });
        return false;
    }
    if (!endDate1) {
        this.setState({ errorResSearch: 'Return Date is required.' });
        return false;
    }
    if (fullTravelObj.countAdult <= 0) {
        this.setState({ errorResSearch: 'Travel count and class is required' });
        return false;
    }
    this.setState({ errorResSearch: '' });
    return true;
};


  validateFields = () => {
    const { inputCityTo, inputCityFrom, startDate, fullTravelObj} = this.state;
    switch (true) {
      case !inputCityFrom:
        this.setState({ errorResSearch: 'Origin city is required.' });
        return false;
      case !inputCityTo:
        this.setState({ errorResSearch: 'Destination city is required.' });
        return false;
        case !startDate:
          this.setState({ errorResSearch: 'Departure Date is required.' });
          return false;
      case fullTravelObj.countAdult <= 0:
        this.setState({ errorResSearch: 'Travel count and class is required' });
        return false;
      default:
        this.setState({ errorResSearch: '' });
        return true;
    }
  }
  SearchDataOneWay = () => {
    const { selectedTripType, fullTravelObj, startDate } = this.state;
    let formattedDeptDate = ""
    if (startDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const day = String(startDate.getDate()).padStart(2, '0');
      formattedDeptDate = `${year}-${month}-${day}`;
    }
    this.DateFilterArray(formattedDeptDate)
    this.setState({ loading: true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.oneWaySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneWayEndPoint + `departure_date=${formattedDeptDate}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${fullTravelObj.travelStatus}&adult=${fullTravelObj.countAdult}&children=${fullTravelObj.countChild}&infant=${fullTravelObj.countInfant}&trip_type=${selectedTripType}`
    );

    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (typeof window !== 'undefined') {
      const url = `${window.location.origin}${window.location.pathname}?departure_date=${formattedDeptDate}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${fullTravelObj.travelStatus}&adult=${fullTravelObj.countAdult}&children=${fullTravelObj.countChild}&infant=${fullTravelObj.countInfant}&trip_type=${selectedTripType}`;
      window.history.pushState({ path: url }, '', url);
    }
  }
  handleGetOneWayRes = (responseJson: AirData) => {
    this.setState({ loading: false,visibleDetailIndex:null });
    if(responseJson!=undefined){
    if (!responseJson.errors && !responseJson.error) {
      this.handleSelectedPopularFilter()
      const arrayData:any = this.mapFlightData(responseJson);
      const aaryAirLinse = this.mapAirlins(arrayData);
      if (arrayData.length > 0) {
        this.setState({
          airLinesData:aaryAirLinse,
          flightAllDataArray: arrayData,
          flightAllDataDummy: arrayData,
          flightAllDataJson:responseJson.flight_list,
          price_range: responseJson.price_range,
          minPrice: responseJson.price_range.min,
          maxPrice: responseJson.price_range.max,
          selectedPrice: responseJson.price_range.max,
          showError: false,
          errorResSearch: ''
        });
      }


    } else {
      this.setState({ errorResSearch: responseJson.error ? responseJson.error.Description : "input can't be blank", showError:true,
      flightAllDataArray: [],
flightAllDataDummy: [],
flightAllDataJson:[],
price_range: {
  min: 0,
  max: 0
},
minPrice: 0,
maxPrice:100,
selectedPrice: 50,
    });
    }
  }
  }
  mapFlightData = (responseJson: AirData) => {
    return responseJson.flight_list.map((item, index) => {
      return this.mapConnectedFlightData(item, index);
    });
  };
  mapAirlins = (responseJson: FlightDataAir[])=> {
    const duplicateData = responseJson.reduce<Record<string, number>>((acc, item) => {
      acc[item.AirlineName] = (acc[item.AirlineName] || 0) + 1;
      return acc;
    }, {});
    const dataAllFlight = responseJson.map((item, index) => ({
      id: index+1,
      Count:duplicateData[item.AirlineName],
      AirlineName: item.AirlineName,
      checked:false,
    }));
  
    const uniqueAirlines = dataAllFlight.reduce<FlightDataAir[]>((acc, current) => {
      if (!acc.find(item => item.AirlineName === current.AirlineName)) {
        acc.push(current);
      }
      return acc;
    }, []);
  
    return uniqueAirlines;
  };
  


  mapConnectedFlightData = (item: Flight ,index:number) => {
   
    let lastChildIndex = item.flight_details[0].flight_info.length - 1;
    return {
      id:index,
      flight_number: item.flight_details[0].flight_info[0].FlightNumber,
      AirlineName:item.flight_details[0].flight_info[0].AirlineName,
            origin: item.flight_details[0].flight_info[0].Origin,
            destination: item.flight_details[0].flight_info[item.connection].Destination,
            connection: item.connection,
            departure_time: item.flight_details[0].flight_info[0].DepartureTime,
            arrival_time: item.flight_details[0].flight_info[0].ArrivalTime,
            per_adult_price: item.adult_per_price,
            duration: item.flight_details[0].travel_time,
      endArrival_time:item.flight_details[0].flight_info[lastChildIndex].ArrivalTime,
     
    };
  };
  convertFormatTime = (isoString: moment.MomentInput) => {
    const formattedDate = moment(isoString).format('hh:mm A');
    return formattedDate
  }
  DateComponent = (dateString: string | number | Date) => {
    const dateObj = new Date(dateString);
  
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      day: 'numeric',
      month: 'short'
    };
  
    const weekday = dateObj.toLocaleDateString('en-GB', { weekday: 'short' });
    const day = dateObj.getDate();
    const month = dateObj.toLocaleDateString('en-GB', { month: 'short' });
  
    const daySuffixes = ['th', 'st', 'nd', 'rd'];
    const relevantDigits = day % 100;
    const daySuffix = daySuffixes[(relevantDigits - 20) % 10] || daySuffixes[relevantDigits] || daySuffixes[0];
  
    const dayWithSuffix = `${day}${daySuffix}`;
    const result = `${weekday}, ${dayWithSuffix} ${month}`;
  
    return result;
  };
  handlePopulerFunct = (items: PopularFilterProps) => {
    items.checked = !items.checked;

    let updatedSelectedFilters = [...this.state.selectedPopularFilter];

    if (items.checked) {
        updatedSelectedFilters.push(items);
    } else {
        updatedSelectedFilters = updatedSelectedFilters.filter(
            filter => filter.name !== items.name
        );
    }
        this.setState({ selectedPopularFilter: updatedSelectedFilters }, () => {
          this.datafilterCustom();
      });
};
datafilterCustom=()=>{
  let selectedAirlines = this.state.selectedAirlines.filter(item => item.checked).map(item => item.AirlineName);
    let customArray: FlightData[] = this.state.flightAllDataDummy;

    let filteredArray = customArray;
    if (selectedAirlines.length > 0) {
        filteredArray = filteredArray.filter(flight => selectedAirlines.includes(flight.AirlineName));
    }
    const filterConditions = [
        { name: "Non-stop", func: this.nonStopFunction },
        { name: "1 Stop", func: this.oneStopFunction },
        { name: "2 Stop", func: this.twoStopFunction },
        { name: "Morning Departures", func: this.morningFunction },
        { name: "Late Departures", func: this.eveningFunction },
    ];

    let anyItemChecked = false;
    let filteredResults = new Set<FlightData>();

    filterConditions.forEach(condition => {
        const item = this.state.selectedPopularFilter.find(
            (item: PopularFilterProps) => item.name === condition.name && item.checked
        );

        if (item) {
            const result = condition.func(item, filteredArray);
            result.forEach(flight => filteredResults.add(flight));
            anyItemChecked = true;
        }
    });

    if (anyItemChecked) {
        filteredArray = Array.from(filteredResults);
    }

    this.setState({ flightAllDataArray: filteredArray });
}


  nonStopFunction = (item: any, dataArray: FlightData[]) => {
    if (item.checked) {
      return dataArray.filter((flight: FlightData) => String(flight.connection) === "0");
    }
    return dataArray;
  };
  twoStopFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      return dataArray.filter((flight: FlightData) => String(flight.connection) === "2");
    }
    return dataArray;
  };
  oneStopFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      return dataArray.filter((flight: FlightData) => String(flight.connection) === "1");
    }
    return dataArray;
  };
  morningFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      const minTime = this.convertToComparableTime("06:00 AM"); 
      return dataArray.filter((flight: FlightData) => {
        const flightTime = new Date(flight.departure_time);
        return this.isAfterMinimumTime(flightTime, minTime);
      });
    }
    return dataArray;
  };
  
  eveningFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      const minTime = this.convertToComparableTime("06:00 PM"); 
      return dataArray.filter((flight: FlightData) => {
        const flightTime = new Date(flight.departure_time);
        return this.isAfterMaxTime(flightTime, minTime);
      });
    }
    return dataArray;
  };
  convertToComparableTime = (time: string) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);
  
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
  
    return date;
  };
  
  isAfterMinimumTime = (flightTime: Date, minTime: Date) => {
    const flightTimeMinutes = flightTime.getHours() * 60 + flightTime.getMinutes();
    const minTimeMinutes = minTime.getHours() * 60 + minTime.getMinutes();
    return flightTimeMinutes <= minTimeMinutes;
  };
  isAfterMaxTime = (flightTime: Date, maxTime: Date) => {
    const flightTimeMinutes = flightTime.getHours() * 60 + flightTime.getMinutes();
  const minTimeMinutes = maxTime.getHours() * 60 + maxTime.getMinutes();
  return flightTimeMinutes >= minTimeMinutes;
  };
  handlePriceRangeChange = (maxValue: number) => {
    this.setState({ selectedPrice: maxValue })
    const filteredData = this.state.flightAllDataDummy.filter((item: FlightData) => {
      return item !== undefined && parseInt(item.per_adult_price) <= maxValue;
    });
    this.setState({ flightAllDataArray: filteredData });
  }
  decreaseCountChildInfant = () => {
    this.setState(prevState => ({
      countInfant: prevState.countInfant === 0 ? prevState.countInfant : prevState.countInfant - 1,
      infant:prevState.countInfant === 0 ? prevState.countInfant : prevState.countInfant - 1,
      error: ""
    }));
  };
  increaseCountChildInfant = () => {
    const {countChild,countInfant,countAdult} = this.state
    this.setState(prevState => {
      const total = countAdult + countChild + countInfant;
      if (total < 9) {
        return {
          countInfant: prevState.countInfant + 1,
          infant:prevState.countInfant + 1,
          error: ""
        };
      } else {
        this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
        return null;
      }
    });
  };
  inputCityFuncFrom=(value: string)=>{   
    this.setState({inputCityFrom:value}) 
  }
  inputCityFunc=(value: string)=>{
    this.setState({inputCityTo:value})
  }
  diffTime=(ArrivalTime:string, DepartureTime:string)=>{
    const arrivalMoment = moment(ArrivalTime);
    const departureMoment = moment(DepartureTime);
  
    const duration = moment.duration(arrivalMoment.diff(departureMoment));
    const hours = duration.hours();
    const minutes = duration.minutes();
   return `${hours} hours ${minutes} minutes`;
  }
  handleShowDetail=(indexKey:number)=>{
    this.setState({visibleDetailIndex: this.state.visibleDetailIndex === indexKey ? null : indexKey})
    const inputData = this.state.flightAllDataJson[indexKey].flight_details[0].flight_info;
    const via = inputData.reduce((acc, flight, index) => {
      if (index === 0) {
        return acc; 
      }
      return acc + (acc ? ', ' : '') + flight.Origin; 
    }, '');
    const dataDetail = inputData.map((item, index) => {
      const changeFlight = (inputData[index].FlightNumber == inputData[index+1]?.FlightNumber) ? false : true;
      let layover_time = '';

    if (inputData[index+1]?.DepartureTime) {
        layover_time = this.diffTime(inputData[index+1]?.DepartureTime,item.ArrivalTime)
    }
     return {
      change_flight: changeFlight,
      origin:item.Origin,
      destination: item.Destination,
      departure_time: item.DepartureTime,
      arrival_time: item.ArrivalTime,
      AirlineName:item.AirlineName,
      FlightNumber:item.FlightNumber,
      duration: this.diffTime(item.ArrivalTime,item.DepartureTime),
      layover_time: layover_time
     }
  })
    const finalOutput = {
        connection: this.state.flightAllDataJson[indexKey].connection,
        via,
        route: inputData.reduce((acc, curr, index) => {
                return acc + (index > 0 ? ' > ' : '') + curr.Destination;
            }, `${inputData[0].Origin} > `),
            layour_info: dataDetail
    }
this.setState({filterFlightDetails:finalOutput})
  }
  navigateScreens = (pathName: string) => {
    const msgsend: Message = new Message(getName(MessageEnum.NavigationMessage));
    msgsend.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msgsend.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msgsend.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsend);
  };
  DateFilterArray =(dateElement: Date | string)=>{
    const originalDate = new Date(dateElement);
    const todayDate = new Date();
  
    const dates = [];
  
    dates.push({ dateItem: this.formatDateArray(todayDate), isHighlighted: todayDate.toDateString() === originalDate.toDateString() });
    for (let i = 1; i <= 300; i++) {
      const newDate = new Date(todayDate);
      newDate.setDate(todayDate.getDate() + i);
  
      const isHighlighted = newDate.toDateString() === originalDate.toDateString();
      dates.push({ dateItem: this.formatDateArray(newDate), isHighlighted});
    }  
    let keyIndex = dates.findIndex((x)=> x.isHighlighted);   
    this.setState({ dateCustomPrices: dates },() => this.scrollToHighlightedDate(keyIndex));
  }
  formatDateArray = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
formatDateFilter = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  const options:Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
  };
  OneWaySearchWithDate = (urlSearchDate: DatePrice) => {
    const date = new Date(urlSearchDate.dateItem);
    this.setState({ loading: true, startDate: date,selectedTripType:"One Way"});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.oneWaySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneWayEndPoint
       + `departure_date=${urlSearchDate.dateItem}&origin=${this.state.searchTextFrom}&destination=
       ${this.state.searchToText}&cabin_class=${this.state.cabin_class}&adult=${this.state.countAdult}&children=
       ${this.state.countChild}&infant=${this.state.infant}&trip_type=${this.state.trip_type}`
    );

    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLayoverMessage=(itemEle:LayourInfoDe, item:number)=> {
    if (itemEle.layover_time !== "") {
      return `Layover in ${itemEle.destination}`;
    } else if (item != 0) {
      return "";
    } else {
      return "Non Stop"; 
    }                 
  }        
  handleArrivalTimeSelect=(time:TimePrice)=>{
  
    let updatedArray: FlightData[] = this.state.flightAllDataDummy;
    const timeRanges:any = {
      "Before 6AM": { start: "00:00", end: "06:00" },
      "6AM to 12PM": { start: "06:00", end: "12:00" },
      "12PM to 6PM": { start: "12:00", end: "18:00" },
      "After 6PM": { start: "18:00", end: "23:59" },
  };
  const selectedTimeRange = timeRanges[time.time];
    let filteredArray = updatedArray.filter((flight) => {
        const flightArrivalTime = new Date(flight.endArrival_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return flightArrivalTime >= selectedTimeRange.start && flightArrivalTime <= selectedTimeRange.end;
    });
    this.setState({ 
        arrivalTimePrice: time,
        flightAllDataArray: filteredArray 
    });
  }
  handleDepartureTimeSelect=(time:TimePrice)=>{
    let updatedArray: FlightData[] = this.state.flightAllDataDummy;
    const timeRanges:any = {
      "Before 6AM": { start: "00:00", end: "06:00" },
      "6AM to 12PM": { start: "06:00", end: "12:00" },
      "12PM to 6PM": { start: "12:00", end: "18:00" },
      "After 6PM": { start: "18:00", end: "23:59" },
  };
  const selectedTimeRange = timeRanges[time.time];
    let filteredArray = updatedArray.filter((flight) => {
        const flightArrivalTime = new Date(flight.departure_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return flightArrivalTime >= selectedTimeRange.start && flightArrivalTime <= selectedTimeRange.end;
    });
    this.setState({ 
      departureTimePrice: time,
        flightAllDataArray: filteredArray 
    });
  }
  airlinsFunct = (items: FlightDataAir) => {
    items.checked = !items.checked;

    let updatedSelectedAirlines = [...this.state.selectedAirlines];

    if (items.checked) {
        updatedSelectedAirlines.push(items);
    } else {
        updatedSelectedAirlines = updatedSelectedAirlines.filter(
            airline => airline.AirlineName !== items.AirlineName
        );
    }
    this.setState({ selectedAirlines: updatedSelectedAirlines }, () => {
        this.datafilterCustom();
    });
};
datafilterCustomAir = (items: FlightDataAir[]) => {
  let selectedAirlines = items.filter(item => item.checked).map(item => item.AirlineName);
  let updatedArray: FlightData[] = this.state.flightAllDataDummy;
  
  let filteredArray;
  if (selectedAirlines.length > 0) {
      filteredArray = updatedArray.filter(flight => selectedAirlines.includes(flight.AirlineName));
  } else {
      filteredArray = updatedArray;
  }
  
  this.setState({
      flightAllDataArray: filteredArray
  });
};
handleClick = (event:React.MouseEvent<HTMLElement>, index = 0) => {
  this.setState({searchText: {
    country: "",
    stateCode: "",
    flight: "",
    state: ""
    
  },inputApiSearchFrom:"", anchorEl: event.currentTarget, currentIndex: index });
};
handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;

  this.setState({inputApiSearchFrom:value
  }, () => {
    if (value !== "") {this.airportSearch(value);
    } else {
      this.setState({
        filteredItems: [],
        searchText: {
          country: "",
          stateCode: "",
          flight: "",
          state: ""
          
        }
      });
    }
  });
 
};

handleClickTo = (event:React.MouseEvent<HTMLElement>, index = 0) => {

  this.setState({ anchorElTo: event?.currentTarget, toText: {
    country: "",
    stateCode: "",
    flight: "",
    state: ""
   
  },currentIndex: index,inputApiSearchTo:"" });
};

handleCloseTo = (selectedItem:SearchText) => {

  this.setState({ anchorElTo: null, currentIndex: 0,
     toText: selectedItem });
};
handleInputChangeTo = (event:React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event?.target; 
  this.setState({ inputApiSearchTo:value
  }, () => {
    if (value !== "") {
      this.airportSearchTo(value);
    } else {
      this.setState({
        filteredItemsTo: [],
        toText: {
          country: "",
          stateCode: "",
          flight: "",
          state: ""
         
        }
      });
    }
  });
 

};
airportSearch=(stateName:string)=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.airportSearchApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.airportSearchEndPoint+ stateName
  );

  const header = {
    "Content-Type": configJSON.productApiContentType,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
handleAirportSearchRes = (responseJson: AirportResponse) => {
  if (responseJson.airport_list != null) {
    const caData = responseJson.airport_list.map((item: AirportSearch, index: number) => {
      let flightName = item.Name.replace("Arpt", "Airport");
      return {
        id: `${index + 1}`,
        country: item.CountryCode,
        state: item.Code,
        flight: flightName,
        stateCode: item.CityCode
      };
    });

    this.setState({ filteredItems: caData });

          this.setState({ 
            searchText: { 
              ...this.state.searchText, 
              state: this.state.searchText.state 
            }, 
          });
  } else {
    this.setState({ searchText: { ...this.state.searchText, state: "" } });
  }
}

airportSearchTo=(stateName:string)=>{
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.airportSearchToApiCallId = requestMessage.messageId;



  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.airportSearchEndPoint+ stateName
  );

  const header = {
    "Content-Type": configJSON.productApiContentType,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );



  runEngine.sendMessage(requestMessage.id, requestMessage);
}
handleAirportSearchToRes=(responseJson:AirportResponse)=>{
  if(responseJson.airport_list!=null){


    const caData = responseJson.airport_list.map((item:AirportSearch, index:number) => {
      let flightName = item.Name.replace("Arpt", "Airport");
      return {
        "id":`${index+1}`,
        "country":item.CountryCode,
        "state":item.Code,
        "flight":flightName,
        "stateCode":item.CityCode 
      }
   })
   this.setState({filteredItemsTo:caData})
      
      this.setState({ 
        toText: { 
          ...this.state.toText, 
          state: this.state.toText.state 
        }, 
      });
    
  }else{
    this.setState({toText: { ...this.state.toText, state: ""}})
  }
   
}
handleSingleClose = (option: SearchText) => {
  this.setState({ anchorEl: null, currentIndex: 0, searchText: option });
}
handleDateChange1 = (ranges: DateRange) => {
  if (this.state.activePicker === 'startDate') {
    let determinedDate = ranges.selection.startDate;
  
    this.setState({
      startDate: determinedDate,
      endDate: determinedDate,

      endDate1: null,
    });
  } 
};
handleCloseCustomAi=()=>{

  this.setState({
    setAnchorAi: null,
    isOpen: false,
  });

}
hanldeSubmitPrice=(item:ViewPrice,priceIndex:number)=>{
this.priceSubmit(item.AirItenanry)
this.setState({AirItenanryData:item.AirItenanry,priceIndex: priceIndex})
}
priceSubmit=(AirItenanry:any)=>{
  this.setState({ loading: true });
  const header = {
    "Content-Type": configJSON.productApiContentType,
  };
  const totalTraveller= {
    "adult":this.state.countAdult,
    "childrens":this.state.countChild,
    "infant":this.state.countInfant,
  }
  localStorage.setItem('totalTraveller', JSON.stringify(totalTraveller));
  const httpBody = {AirItenanry, 
    "adult":this.state.fullTravelObj.countAdult ,
 "children": this.state.fullTravelObj.countChild,
 "infant":this.state.fullTravelObj.countInfant };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.priceApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.priceAPiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypePost
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);  
}
handlePriceResponse=(responseJson:any)=>{
  this.setState({ loading: false });
  if (!responseJson.air_pricing.ErrorInfo && responseJson) {
   
    this.setState({errorPriceInfo:""})
    const promoCodeMessage: Message = new Message(
      getName(MessageEnum.OrderManagementDataMessage)
    );
    promoCodeMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    promoCodeMessage.addData(getName(MessageEnum.OrderManagementDataInfo),{bookingData: responseJson,AirItenanryData:this.state.AirItenanryData});
    this.send(promoCodeMessage);
    }
    else{
      this.setState({errorPriceInfo:responseJson.air_pricing.ErrorInfo.Description})
    }
}

  roundTripApi = () => {
    const { selectedTripType, fullTravelObj, startDate ,endDate1} = this.state
    let formattedDeptDate = ""
    let formattedReturnDate = ""
    if (startDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const changeDay = String(startDate.getDate()).padStart(2, '0');
      formattedDeptDate = `${year}-${month}-${changeDay}`;
    }
    if (endDate1) {
      const year = endDate1.getFullYear();
      const month = String(endDate1.getMonth() + 1).padStart(2, '0');
      const changeDay = String(endDate1.getDate()).padStart(2, '0');
      formattedReturnDate = `${year}-${month}-${changeDay}`;
    }
    this.DateFilterArray(formattedDeptDate)
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.roundTripApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.oneWayEndPoint}departure_date=${formattedDeptDate}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${fullTravelObj.travelStatus}&adult=${fullTravelObj.countAdult}&children=${fullTravelObj.countChild}&infant=${fullTravelObj.countInfant}&trip_type=${selectedTripType}&return_date=${formattedReturnDate}`,
    );
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (typeof window !== 'undefined') {
      const urlName = `${window.location.origin}${window.location.pathname}?departure_date=${formattedDeptDate}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${fullTravelObj.travelStatus}&adult=${fullTravelObj.countAdult}&children=${fullTravelObj.countChild}&infant=${fullTravelObj.countInfant}&trip_type=${selectedTripType}&return_date=${formattedReturnDate}`;
      window.history.pushState({ path: urlName }, '', urlName);
    }
  }
  roundTripResponse = (responseJson: AirData) => {
    this.setState({ loading: false, visibleDetailIndex: null });
    if (responseJson != undefined) {
      if (!responseJson.errors && !responseJson.error) {
        const urlName = `RoundTrip?departure_date=${moment(this.state.startDate).format('YYYY-MM-DD')}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${this.state.fullTravelObj.travelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=round_trip&infant=${this.state.countInfant}&return_date=${moment(this.state.endDate1).format('YYYY-MM-DD')}`;
        window.location.href = urlName;
      } else {
        this.setState({ errorResSearch:responseJson.error?responseJson.error.Description:"input can't be blank" });
      }
    }
  }
  clearAllFunct=()=>{
    const updatedAirlinesData = this.state.airLinesData.map(item => ({
      ...item,
      checked: false
  }));
    this.handleSelectedPopularFilter()
     
    this.setState({
      selectedAirlines: [],
      departureTimePrice: null,
      arrivalTimePrice: null,
      flightAllDataArray: this.state.flightAllDataDummy,
      selectedPrice: this.state.maxPrice
  }, () => {
      this.setState({ airLinesData: updatedAirlinesData });
  });
  }
  // Customizable Area End
}