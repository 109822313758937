
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React,{ RefObject } from "react";
interface UserProfile {
  errors: {token: string}[] | string[]
  data: {
    id: string;
    type: string;
    attributes: {
      image: string| null
      activated: boolean;
      country: string | null;
      email: string;
      phone_number: string | null;
      gender: string | null;
      date_of_birth: string | null;
      address1: string | null;
      address2: string | null;
      passport_expiry_date: string | null;
      passport_number: string | null;
      zip_code: string | null;
      city: string | null;
      state: string | null;
      country_code: string | null;
      full_name: string | null
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuOpen:boolean;
  authToken:string | null;
  anchorEl:Element | null
  userName: string
  recordInfo: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollRef: RefObject<HTMLDivElement> ;
  // Customizable Area Start
  getUserProfileApiCallId: string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = React.createRef<HTMLDivElement>();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      menuOpen:false,
      authToken:"",
      anchorEl:null,
      userName: '',
      recordInfo: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  

  // Customizable Area Start
  async componentDidMount() {
    this.tokenGetFunct()
    if (this.scrollRef.current) {
      window.scrollTo(0, 0);
    }
    this.handleGetProfileDetails()
  }
  tokenGetFunct=()=>{
    const token = localStorage.getItem('authToken');
    if(token){
      this.setState({authToken:token})
    }
  }
  handleMenuToggle = () => {
   this.setState({menuOpen:!this.state.menuOpen})
};
handleMenuOpen = (event:React.MouseEvent<HTMLElement>) => {
  this.setState({ anchorEl: event.currentTarget });
};

handleMenuClose = () => {
  this.setState({ anchorEl: null });
};
logoutFunct=()=>{
  localStorage.removeItem("authToken")
  localStorage.removeItem("userData")
  this.setState({authToken:""})
  this.props.navigation.navigate("Home");
 
}
loginRedirect=()=>{
  this.props.navigation.navigate("EmailAccountLoginBlock");
}
  handleHome = (itemKey: string) => {
    this.props.navigation.navigate(itemKey);
  }
  handleProfile = () => {
    window.location.href = "userProfile"
  }
  async receive(from: String, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (true) {
        case apiRequestCallId === this.getUserProfileApiCallId:
          this.handleProfileSuccess(responseJson)
          break;
      }
    }
  }
  handleGetProfileDetails = () => {
    const token = localStorage.getItem("authToken");
    const userDataString = localStorage.getItem("userData");
    if (token) {
      if (userDataString) {
        const userData: { id: string; type: string } = JSON.parse(userDataString);
        const id = userData.id;

        const header = {
          "Content-Type": configJSON.contentTypeApiGetUserProfile,
          token: token
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getProfileApiEndpoint + id
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      this.logoutFunction()
    }
  };
  
  logoutFunction = () => {
    if (this.state.recordInfo != '') {
      window.location.reload()
      this.setState({ authToken: '' })
      localStorage.clear()
    }
    else {
      this.setState({ authToken: '' })
      localStorage.clear()
    }
  }
  
  handleProfileSuccess = (response: UserProfile) => {
    const { errors, data } = response;
    if (errors && Array.isArray(errors)) {
      const error = errors[0];
      if (typeof error === 'object' && error.token) {
        if (error.token) {
          this.setState({recordInfo:error.token})
          this.logoutFunction();
        }
      } else if (error === 'Record not found') {
        this.setState({recordInfo:error})
        this.logoutFunction();
      }
      return;
    }

    if (data && data.attributes) {
      const {
        full_name,
      } = data.attributes;
      this.setState({
        userName: full_name ? full_name : '',
      });
    }
  };
  // Customizable Area End
}
