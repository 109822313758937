import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import changeMoment from "moment";
import React from "react";
export interface ResDestinationType {
  data: DestinationProps[];
}
interface AirportSearchProps {
  id: string;
  Code: string;
  Name: string;
  CityCode: string;
  CountryCode: string;
}

interface AirportResponseProps {
  airport_list: AirportSearchProps[] | null;
}
interface ResBlog{
  data:Blog[]
}
interface Blog{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name":string,
    "description":string,
    "created_at": string,
    "body":string,
    "image": string
}
}
export interface DestinationProps {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    name_of_destination: string;
    created_at: string;
    image: string;
  };
}
type DateRangeProps = {
  selection: {
    startDate: Date;
    endDate: Date;
  };
};
interface ErrorResponseProps {
  errors: string[];
}

export interface StopProps {
  0: number;
  1: string;
}

export interface SegmentKeyProps {
  Key: string;
}

export interface AirSegmentRefProps {
  Key: string;
  FlightNumber: string;
  Origin: string;
  Destination: string;
  DepartureTime: string;
  ArrivalTime: string;
  FlightTime: string;
  Distance: string;
  ETicketability: string;
  Equipment: string;
  FlightDetailsRef: {
    Key: string;
  };
}

export interface BookingInfoProps {
  BookingCode: string;
  BookingCount: string;
  CabinClass: string;
  FareInfoRef: string;
  SegmentRef: string;
}

export interface TaxInfoProps {
  Category: string;
  Amount: string;
  Key: string;
}

export interface BaggageAllowanceProps {
  MaxWeight: {
    Value: string;
    Unit: string;
  };
}

export interface FareSurchargeProps {
  Key: string;
  Type: string;
  Amount: string;
}

export interface FareInfoProps {
  Key: string;
  FareBasis: string;
  PassengerTypeCode: string;
  Origin: string;
  Destination: string;
  EffectiveDate: string;
  DepartureDate: string;
  Amount: string;
  NegotiatedFare: string;
  NotValidBefore: string;
  NotValidAfter: string;
  FareSurcharge: FareSurchargeProps;
  BaggageAllowance: BaggageAllowanceProps;
  FareRuleKey: string;
  Brand: {
    Key: string;
    BrandID: string;
    UpSellBrandID: string;
    BrandTier: string;
    UpsellBrand: {
      FareBasis: string;
      FareInfoRef: string;
    };
  };
}

export interface AirPricingInfoProps {
  Key: string;
  TotalPrice: string;
  BasePrice: string;
  ApproximateTotalPrice: string;
  ApproximateBasePrice: string;
  Taxes: string;
  ApproximateTaxes: string;
  LatestTicketingTime: string;
  PricingMethod: string;
  Refundable: string;
  ETicketability: string;
  PlatingCarrier: string;
  ProviderCode: string;
  Cat35Indicator: string;
  FareInfoRef: {
    Key: string;
  }[];
  BookingInfoProps: BookingInfoProps[];
  TaxInfoProps: TaxInfoProps[];
  FareCalc: string;
  PassengerType: {
    Code: string;
  };
  ChangePenalty: {
    PenaltyApplies: string;
    Amount: string;
  };
  CancelPenalty: {
    PenaltyApplies: string;
    Amount: string;
  };
  FareInfoList: FareInfoProps[];
  Brand: {
    Key: string;
    BrandID: string;
    Name: string;
    BrandedDetailsAvailable: string;
    Carrier: string;
    Title: string[];
    Text: string[];
    ImageLocation: string[];
  }[];
}
interface AirPricingSolutionProps {
  air_pricing_key: string;
  total_price: string;
  base_price: string;
  taxes: string;
  connection: number;
  travel_time: string;
  currency_type: string;
  adult_per_price: string;
  segment_ref_key: SegmentKeyProps[];
  air_segment_refs: AirSegmentRefProps[];
  air_pricing_info: AirPricingInfoProps;
}
export interface FlightProps {
  air_segment_keys: SegmentKeyProps[];
  adult_per_price: string;
  connection: number;
  air_pricing_solution: AirPricingSolutionProps[];
}

export interface PriceRangeProps {
  min: number;
  max: number;
}

export interface AirDataProps {
  stops: StopProps[];
  price_range: PriceRangeProps;
  flight_list: FlightProps[];
  error: ErrorSecProps;
  errors: string;
}
export interface ErrorSecProps {
  Description: string;
}
export interface SingleOfferType {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      title: string;
      tagline1: string;
      tagline2: string;
      offer_code: string;
      offer_type: string;
      travel_city: string;
      travel_date: string | null;
      expiry_date: string | null;
      created_at: string;

      discount_amount: string | null;
      description: string | null;
      image: string;
      offer_code_image: string;
    };
  };
  errors: {
    massage: string;
  };
}
export interface PromocodeAttributes {
  discount_type: "fixed" | "percentage";
  discount: number;
  min_order_amount: number;
  name: string;
  to: string;
  description?: string;
}
export interface Promocode {
  attributes: PromocodeAttributes;
}
export interface MulticityArryType {
  from: ListCityDummyType | null;
  to: ListCityDummyType | null;
  departureDate: Date | null;
}
interface ListCityDummyType {
  id: string;
  country: string;
  state: string;
  flight: string;
  stateCode: string;
}
interface SearchText {
  id: string;
  state: string;
  country: string;
  flight: string;
  stateCode: string;
}
interface ResOffer {
  data: Offer[];
}
interface Offer {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    tagline1: string;
    tagline2: string;
    offer_code: string;
    image: string;
  };
}
interface ResBenifit {
  data: BeniFitUser[];
}
interface BeniFitUser {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    image: string;
  };
}
interface WhereTo {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    destination: string;
    root: string;
    image: string;
  };
}
interface Blog {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    created_at: string;
    body: string;
    image: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  promocodes: Array<string>;
  loading: boolean;
  cityId: string;
  token: string;
  destinationData: DestinationProps[];
  selectedOption: string;
  multicityTrips: MulticityArryType[];
  startDate: Date | null;
  endDate: Date | null;
  promocode?: Promocode;
  singleOfferData: SingleOfferType;
  endDate1: Date | null;
  errorMessage: string;
  errorResSearch: string;
  isOpen: boolean;
  toText: {
    state: string;
    country: string;
    flight: string;
    stateCode: string;
  };
  searchText: {
    state: string;
    country: string;
    flight: string;
    stateCode: string;
  };
  fullTravelObj: {
    countAdult: number;
    countChild: number;
    countInfant: number;
    TravelStatus: string;
  };
  countAdult: number;
  countInfant: number;
  TravelStatus: string;
  anchorEl: Element | null;
  countChild: number;
  anchorElTo: Element | null;
  currentIndex: number;
  searchField: string;
  listCityDummy: ListCityDummyType[];
  listCityDummyM: ListCityDummyType[];
  filteredItems: SearchText[];
  filteredItemsM: SearchText[];
  filteredItemsTo: SearchText[];
  activePicker: string;
  inputCityTo: string;
  inputCityFrom: string;
  offerForYou: Offer[];
  popularDestination: DestinationProps[];
  blogs: Blog[];
  BenefitUser: BeniFitUser[];
  whereToGo: WhereTo[];
  authToken: string | null;
  searchTextClass: {
    classNumber: string;
    title: string;
    travelClass: string;
  };
  anchorElClass: Element | null;
  error: string;
  setAnchorAi: Element | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewallBlogsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiPromoCodeCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      popularDestination: [],
      blogs: [],
      BenefitUser: [],
      whereToGo: [],
      authToken: "",
      isOpen: false,
      startDate: null,
      endDate: null,
      listCityDummyM: [
        {
          id: "1",
          country: "india",
          state: "New Delhi",
          flight: " Indira Gandhi International Airport",
          stateCode: "DEL",
        },
        {
          id: "2",
          country: "india",
          state: "Mumbai",
          flight: "Shivaji International Airport",
          stateCode: "BOM",
        },
        {
          id: "3",
          country: "Bangkok",
          state: "Bangkok",
          flight: " Indira Gandhi International Airport",
          stateCode: "BKK",
        },
      ],
      multicityTrips: [{ from: null, to: null, departureDate: null }],
      searchText: {
        state: "",
        country: "",
        flight: "",
        stateCode: "",
      },
      anchorElClass: null,
      countAdult: 1,
      countChild: 0,
      countInfant: 0,
      TravelStatus: "PremiumEconomy",
      fullTravelObj: {
        
        countChild: 0,countAdult: 1,
        countInfant: 0,
        TravelStatus: "PremiumEconomy",
      },
      error: "",
      promocodes: [],
      cityId: "",
      token: "",
      promocode: undefined,
      singleOfferData: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            title: "",
            tagline1: "",
            tagline2: "",
            offer_code: "",
            offer_type: "",
            travel_city: "",
            travel_date: null,
            expiry_date: null,
            created_at: "",
            discount_amount: null,
            description: null,
            image: "",
            offer_code_image: "",
          },
        },
        errors: {
          massage: "",
        },
      },
      setAnchorAi: null,
      filteredItemsM: [],
      errorMessage: "",
      loading: false,
      inputCityTo: "",
      inputCityFrom: "",
      errorResSearch: "",
      listCityDummy: [],
      anchorEl: null,
      anchorElTo: null,
      filteredItems: [],
      filteredItemsTo: [],
      searchField: "",
      currentIndex: 0,
      toText: {
        state: "",
        country: "",
        flight: "",
        stateCode: "",
      },

      selectedOption: "One Way",
      activePicker: "",
      offerForYou: [],
      endDate1: null,
      searchTextClass: {
        classNumber: "",
        title: "",
        travelClass: "",
      },
      destinationData: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.blogFunct();

    // Customizable Area End
  }

  getToken = () => {
    const msgConst: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msgConst);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.responseRecive(apiRequestCallId, responseJson);
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.airportSearchApiCallId &&
            this.airportSearchApiCallId != null:
            this.handleAirportSearchResponse(responseJson);
            break;
            case apiRequestCallId === this.blogApiCallId &&  this.blogApiCallId != null:
            this.handleGetAllBlogList(responseJson);
            break;
          case apiRequestCallId === this.airportSearchToApiCallId &&
            this.airportSearchToApiCallId != null:
            this.handleAirportSearchToResponse(responseJson);
            break;
          case apiRequestCallId === this.oneWaySearchApiCallId &&
            this.oneWaySearchApiCallId != null:
            this.handleGetOneWayResponse(responseJson);
            break;
          default:
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getSingleOffersApiCallId: string = "";
  getDestinationApiCallId: string = "";
  oneWaySearchApiCallId: string = "";
  roundTripSearchApiCallId: string = "";
  airportSearchToApiCallId: string = "";
  airportSearchApiCallId: string = "";
  blogApiCallId: string = ""
  offerForYouApiCallId: string = "";
  calendarDivRef = React.createRef<HTMLDivElement>();

  blogFunct = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blogApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogEndPoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClickBlogDetails = (idBlog:string) => {
    this.props.navigation.navigate("SingleBlog", { id: idBlog})
  };
  handleGetAllBlogList=(responseJson:ResBlog)=>{
    if(responseJson.data.length>0){
      this.setState({blogs:responseJson.data})
    }else{
      this.setState({blogs:[]})
    }
    }
  responseRecive = (
    apiRequestCallId: string,
    responseJson: AirDataProps & ErrorResponseProps
  ) => {
    if (responseJson && !responseJson.errors) {
      this.responseSucessCell(apiRequestCallId, responseJson);
    } else if (responseJson && responseJson.errors) {
      this.responseFailureCell(apiRequestCallId, responseJson);
    }
  };
  responseSucessCell = async (
    apiRequestCallId: string,
    responseJson: AirDataProps
  ) => {
    if (apiRequestCallId === this.roundTripSearchApiCallId) {
      this.roundTripSearchSucessCallBack(responseJson);
    }
  };

  responseFailureCell = async (
    apiRequestCallId: string,
    responseJson: ErrorResponseProps
  ) => {
    if (apiRequestCallId === this.roundTripSearchApiCallId) {
      this.roundTripSearchFailureCallBack(responseJson);
    }
  };
  handleGetOneWayResponse = (responseJson: AirDataProps) => {
    this.setState({ loading: false });
    if (!responseJson.errors && !responseJson.error) {
      let formattedDate1 = this.setDateFunction();
      const url = `Catalogue?departure_date=${formattedDate1}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&infant=${this.state.countInfant}&trip_type=one_way`;
      window.location.href = url;
    } else {
      this.setState({
        errorResSearch: responseJson.error
          ? responseJson.error.Description
          : "input can't be blank",
      });
    }
  };
  shortDescription = (str:string, maxLength:number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  roundTripSearchSucessCallBack = (response: AirDataProps) => {
    if (!response.error && !response.errors) {
      let formattedDate1 = this.setDateFunction();
      const urlName = 
      `RoundTrip?departure_date=${formattedDate1}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=round_trip&infant=${this.state.countInfant}
      &return_date=${changeMoment(this.state.endDate1).format('YYYY-MM-DD')}`;
      window.location.href = urlName;
    }
    else this.setState({errorResSearch:response.error ? response.error.Description : "Error while searching the round trip" ,loading:false})
  };

  roundTripSearchFailureCallBack = (response: ErrorResponseProps) => {
    this.setState({ errorResSearch: response.errors[0], loading: false });
  };

  handleSelectRadio = (value: string) => {
    this.setState({ selectedOption: value });
    if (value === "One Way" || value === "Roundtrip") {
      this.setState({
        multicityTrips: [{ from: null, to: null, departureDate: null }],
        selectedOption: value,
        startDate: null,
        endDate: null,
        endDate1: null,
      });
    } else if (value === "Multicity") {
      this.setState({
        startDate: null,
        endDate: null,
        endDate1: null,
        multicityTrips: [
          { from: null, to: null, departureDate: null },
          { from: null, to: null, departureDate: null },
        ],
      });
    }
  };

  searchFlight = () => {
    if (this.state.selectedOption === "One Way") {
      if (this.checkOneWayValidation()) {
        this.OneWaySearchFunction();
      }
    } else if (this.state.selectedOption === "Roundtrip") {
      if (this.roundTripInputValidation()) {
        this.roundTripSearchApiFunc();
      }
    }
  };
  roundTripInputValidation = () => {
    const { toText, searchText, startDate, fullTravelObj, endDate1 } =
      this.state;
    if (!searchText.state) {
      this.setState({ errorMessage: "Origin city is required." });
      return false;
    } else if (!startDate) {
      this.setState({ errorMessage: "Departure Date is required." });
      return false;
    } else if (!endDate1) {
      this.setState({ errorMessage: "Return Date is required." });
      return false;
    } else if (!toText.state) {
      this.setState({ errorMessage: "Destination city is required." });
      return false;
    } else if (fullTravelObj.countAdult <= 0) {
      this.setState({ errorMessage: "Travel count and class is required" });
      return false;
    } else {
      this.setState({ errorMessage: "" });
      return true;
    }
  };

  roundTripSearchApiFunc = async () => {
    this.setState({ loading: true });
    let formattedDate = this.setDateFunction();
    this.roundTripSearchApiCallId = await this.roundTripSearchApiFunct({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${
        configJSON.oneWayEndPoint
      }departure_date=${formattedDate}&origin=${
        this.state.searchText.state
      }&destination=${this.state.toText.state}&cabin_class=${
        this.state.TravelStatus
      }&adult=${this.state.countAdult}&children=${
        this.state.countChild
      }&trip_type=round_trip&infant=${
        this.state.countInfant
      }&return_date=${changeMoment(this.state.endDate1).format("YYYY-MM-DD")}`,
    });
  };
  setDateFunction = (): string | null => {
    if (this.state.startDate === null) {
      return null;
    }

    let year = this.state.startDate.getFullYear();
    let month = (this.state.startDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    let day = this.state.startDate.getDate().toString().padStart(2, "0");

    const stDate = `${year}-${month}-${day}`;
    return stDate;
  };
  OneWaySearchFunction = () => {
    this.setState({ loading: true });
    let formattedDate = this.setDateFunction();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.oneWaySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneWayEndPoint +
        `departure_date=${formattedDate}&origin=${this.state.searchText.state}&destination=${this.state.toText.state}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&infant=${this.state.countInfant}&trip_type=one_way`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  roundTripSearchApiFunct = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body, apiType } = valueData;

    let clientProfileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let header = {
      "Content-Type": contentType,
    };
    clientProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    clientProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    clientProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      clientProfileRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );

    runEngine.sendMessage(
      clientProfileRequestMessage.id,
      clientProfileRequestMessage
    );
    return clientProfileRequestMessage.messageId;
  };

  checkOneWayValidation = () => {
    const { toText, searchText, startDate, fullTravelObj } = this.state;
    switch (true) {
      case !toText.state:
        this.setState({ errorMessage: "Destination city is required." });
        return false;
      case fullTravelObj.countAdult <= 0:
        this.setState({ errorMessage: "Travel count and class is required" });
        return false;
      case !startDate:
        this.setState({ errorMessage: "Departure Date is required." });
        return false;
      case !searchText.state:
        this.setState({ errorMessage: "Origin city is required." });
        return false;
      default:
        this.setState({ errorMessage: "" });
        return true;
    }
  };
  handleClick = (event: React.MouseEvent<HTMLElement>, index = 0) => {
    this.setState({ anchorEl: event.currentTarget, currentIndex: index,inputCityFrom:"",searchText: {
      state: "",
      country: "",
      stateCode: "",
      flight: ""
    } });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      anchorElTo: null,
      currentIndex: 0,
      searchField: "",
      filteredItems: [],
      filteredItemsTo: [],
    });
  };
  handleInputMunChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    this.setState({ searchField: value });

    if (value) {
      const filteredItemsM = this.state.listCityDummyM.filter((item) =>
        item.state.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ filteredItemsM });
    } else {
      this.setState({ filteredItemsM: [] });
    }
  };
  handleMultiClose = (selectedItem: ListCityDummyType, field: string) => {
    const { multicityTrips, currentIndex } = this.state;
    if (field === "from" && multicityTrips[currentIndex]) {
      multicityTrips[currentIndex].from = { ...selectedItem };
    } else if (field === "to" && multicityTrips[currentIndex]) {
      multicityTrips[currentIndex].to = { ...selectedItem };
    }
    this.setState({
      anchorEl: null,
      anchorElTo: null,
      filteredItemsM: [],
      searchField: "",
      multicityTrips,
      currentIndex: 0,
    });
  };
  reverseFunct = () => {
    this.setState({
      toText: this.state.searchText,
      searchText: this.state.toText,
    });
  };
  handleClickToFunct = (event: React.MouseEvent<HTMLElement>, index = 0) => {
    this.setState({ anchorElTo: event.currentTarget, currentIndex: index,inputCityTo:"", toText: {
      state: "",
      country: "",
      stateCode: "",
      flight: "",
    } });
  };

  handleCloseToFunct = (selectedItem: SearchText) => {
    this.setState({ anchorElTo: null, currentIndex: 0, toText: selectedItem,inputCityTo:"" });
  };

  handleInputChangeToFunct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({inputCityTo:value})
    this.setState(
      {
        toText: { ...this.state.toText, state: value },
      },
      () => {
        if (value !== "") {
          this.airportApiSearchTo(value);
        } else {
          this.setState({
            filteredItemsTo: [],
            toText: {
              state: "",
              country: "",
              stateCode: "",
              flight: "",
            },
          });
        }
      }
    );
  };
  airportApiSearchTo = (stateName: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.airportSearchToApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.airportSearchEndPoint + stateName
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  formatDateFunc = (date: Date) => {
    if (date) {
      const dayaa = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear().toString().slice(-2);
      const weekday = date.toLocaleString("default", { weekday: "long" });
      return `${dayaa} ${month}’${year} ${weekday}`;
    }
  };
  formatDatePartFunc = (value: Date, part: string) => {
    let dateValue = this.formatDateFunc(value);
    if (dateValue) {
      const parts = dateValue.split(" ");
      switch (part) {
        case "day":
          return parts[0];
        case "month":
          return parts[1];
        case "year":
          return parts[2];
      }
    }
  };
  togglePickerMulti = (picker: string, index: number) => {
    this.setState({
      isOpen: true,
      activePicker: picker,
      currentIndex: index,
    });
  };
  handleDateMultiChange = (ranges: DateRangeProps) => {
    const { multicityTrips, currentIndex } = this.state;

    if (this.state.activePicker === "startDate") {
      let determinedDate = ranges.selection.startDate;
      multicityTrips[currentIndex].departureDate = ranges.selection.startDate;

      if (
        (!this.state.startDate && !this.state.endDate) ||
        (this.state.startDate == ranges.selection.startDate &&
          this.state.endDate == ranges.selection.startDate)
      ) {
        determinedDate = ranges.selection.endDate;
        multicityTrips[currentIndex].departureDate = determinedDate;
      }
      this.setState({
        endDate: determinedDate,
        endDate1: null,
        startDate: determinedDate,
      });
    }
  };
  decreaseCountFunct = () => {
    this.setState((prevState) => ({
      countAdult:
        prevState.countAdult === 1
          ? prevState.countAdult
          : prevState.countAdult - 1,
      error: "",
    }));
  };

  increaseCountChildFunct = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countChild: prevState.countChild + 1,
          error: "",
        };
      } else {
        this.errorFunction(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  decreaseCountChildFunct = () => {
    this.setState((prevState) => ({
      countChild:
        prevState.countChild === 0
          ? prevState.countChild
          : prevState.countChild - 1,
      error: "",
    }));
  };

  increaseCountChildInfantFunct = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countInfant: prevState.countInfant + 1,
          error: "",
        };
      } else {
        this.errorFunction(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  decreaseCountChildInfantFunct = () => {
    this.setState((prevState) => ({
      countInfant:
        prevState.countInfant === 0
          ? prevState.countInfant
          : prevState.countInfant - 1,
      error: "",
    }));
  };
  errorFunction = (errorMsg: string) => {
    this.setState({
      error: errorMsg,
    });
  };
  handleSelectStatus = (buttonName: string) => {
    this.setState({
      TravelStatus: buttonName,
    });
  };
  handleCancelTravelClass = () => {
    this.setState({
      TravelStatus: "PremiumEconomy",
      countAdult: 1,
      countChild: 0,
      fullTravelObj: {
        countChild: 0,
        countInfant: 0,
        TravelStatus: "PremiumEconomy",countAdult:
         1,
      },
      anchorElClass: null,
      error: "",
    });
  };
  handleSaveTravelClass = () => {
    this.setState({
      fullTravelObj: {
        countAdult: this.state.countAdult,
        countChild: this.state.countChild,
        countInfant: this.state.countInfant,
        TravelStatus: this.state.TravelStatus,
      },
      anchorElClass: null,
    });
  };
  addAnotherCity = () => {
    const { multicityTrips } = this.state;
    if (multicityTrips.length < 6) {
      this.setState({
        multicityTrips: [
          ...multicityTrips,
          { from: null, to: null, departureDate: null },
        ],
      });
    }
  };
  increaseCount = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countAdult: prevState.countAdult + 1,
          error: "",
        };
      } else {
        this.errorFunction(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };
  iconCloseRemoveItem = (idTem: number) => {
    const updatedTrips = this.state.multicityTrips.filter(
      (trip: MulticityArryType, index: number) => index !== idTem
    );
    this.setState({ multicityTrips: updatedTrips });
  };
  handleClickClass = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElClass: event.currentTarget });
  };
  handleCloseClass = () => {
    this.setState({ anchorElClass: null });
  };
  handleApply = () => {
    this.setState({ isOpen: false, activePicker: "" });
  };

  handleCancel = () => {
    this.setState({
      startDate: null,
      activePicker: "",
      endDate: null,
      endDate1: null,
      isOpen: false,
    });
  };

  airportSearchValue = (stateName: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.airportSearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.airportSearchEndPoint + stateName
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleAirportSearchResponse = (responseJson: AirportResponseProps) => {
    if (responseJson.airport_list != null) {
      const bData = responseJson.airport_list.map(
        (item: AirportSearchProps, index: number) => {
          let flightName = item.Name.replace("Arpt", "Airport");
          return {
            id: `${index + 1}`,
            country: item.CountryCode,
            state: item.Code,
            flight: flightName,
            stateCode: item.CityCode,
          };
        }
      );
      const filteredItems = bData.filter((item) =>
        item.flight
          .toLowerCase()
          .includes(this.state.searchText.flight.toLowerCase())
      );

      this.setState({
        listCityDummy: bData,
        searchText: {
          ...this.state.searchText,
          state: this.state.searchText.state,
        },
        filteredItems,
      });
    } else {
      this.setState({ searchText: { ...this.state.searchText, state: "" } });
    }
  };
  handleSingleClose = (option: SearchText) => {
    this.setState({ anchorEl: null, currentIndex: 0, searchText: option });
  };
  togglePicker = (event: React.MouseEvent<HTMLDivElement>, picker: string) => {
    this.setState({
      isOpen: true,
      activePicker: picker,
      setAnchorAi: event.currentTarget,
    });
    if (picker === "endDate") {
      this.setState({ selectedOption: "Roundtrip" });
    }
  };
  handleDateChange = (ranges: DateRangeProps) => {
    const { startDate, endDate } = ranges.selection;
    this.setState({
      startDate,
      endDate,
      endDate1: endDate,
    });
  };

  handleDateChange1 = (ranges: DateRangeProps) => {
    if (this.state.activePicker === "startDate") {
      let determinedDate = ranges.selection.startDate;

      this.setState({
        startDate: determinedDate,
        endDate: determinedDate,
        endDate1: null,
      });
    }
  };
  handleCloseCustomAi = () => {
    this.setState({
      setAnchorAi: null,
      isOpen: false,
    });
  };
  iconClose = () => {
    this.setState({ endDate: this.state.startDate, endDate1: null });
  };
  handleAirportSearchToResponse = (responseJson: AirportResponseProps) => {
    if (responseJson.airport_list != null) {
      const bData = responseJson.airport_list.map(
        (item: AirportSearchProps, index: number) => {
          let flightName = item.Name.replace("Arpt", "Airport");
          return {
            id: `${index + 1}`,
            country: item.CountryCode,
            state: item.Code,
            flight: flightName,
            stateCode: item.CityCode,
          };
        }
      );
      const filteredItemsTo =
       bData.filter((item) =>
      item.flight
        .toLowerCase()

        .includes(this.state.toText.flight.toLowerCase())
    );
      this.setState({
        listCityDummy: bData,
        toText: {
          ...this.state.toText,
          state: this.state.toText.state,
        },
        filteredItemsTo,
      });
    } else {
      this.setState({ toText: { ...this.state.toText, state: "" } });
    }
  };
  handleInputChangeFrom = (event: React.ChangeEvent<HTMLInputElement>,index=0) => {
    this.setState({ anchorEl: event.currentTarget, currentIndex: index });
    const { value } = event.target;
    this.setState({inputCityFrom: value})
    this.setState({
      searchText: { ...this.state.searchText, state: value }
    }, () => {
      if (value !== "") {
        this.airportSearchValue(value);
      } else {
        this.setState({
          filteredItems: [],
          searchText: {
            state: "",
            country: "",
            stateCode: "",
            flight: ""
          }
        });
      }
    });
   
  };
  handleNavigateB= (item: string) => {
    this.props.navigation.navigate(item);
  }
  // Customizable Area End
}
